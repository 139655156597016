import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { useSimulation } from "../contexts/SimulationContext";

import IntroductionPanel from './Homepage/IntroductionPanel';
import BusinessModelPanel from './Homepage/BusinessModelPanel';
import TimelinePanel from './Homepage/TimelinePanel';
import MarketOpportunityPanel from './Homepage/MarketOpportunityPanel';
import TargetAudiencePanel from './Homepage/TargetAudiencePanel';
import ExitStrategyPanel from './Homepage/ExitStrategyPanel';

export default function Homepage() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();

  return (
    <div className="flex flex-col gap-5 w-full">
      <div id="tour-start" className="absolute top center"></div>
      <div className="flex flex-row gap-5">
        <IntroductionPanel />
        <BusinessModelPanel />
      </div>
      <MarketOpportunityPanel />
      <TimelinePanel />
      <div className="flex flex-row gap-5">
        <TargetAudiencePanel />
        <ExitStrategyPanel />
      </div>
    </div>
  );
}