import { useSimulation } from "../../contexts/SimulationContext";
import clsx from 'clsx';

export default function ExitStrategyHeader() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();

  const updateScenario = (newScenario) => {
    setConfiguration((prevConfig) => ({
      ...prevConfig,
      scenario: newScenario
    }));
  };

  return (
    <header className="flex justify-between items-center">
      <h1 className="text-2xl font-BarlowBold text-white">Exit Strategy</h1>
      <div className="flex space-x-3">
        <button
          onClick={() => { updateScenario('best_case') }}
          className={clsx(
            "bg-gradient-to-b text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
            { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": configuration.scenario === 'best_case' },
            { "from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": configuration.scenario !== 'best_case' }
          )}
        >
          Best Case
        </button>
        <button
          onClick={() => { updateScenario('moderate_case') }}
          className={clsx(
            "bg-gradient-to-b text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
            { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": configuration.scenario === 'moderate_case' },
            { "from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": configuration.scenario !== 'moderate_case' }
          )}
        >
          Moderate Case
        </button>
        <button
          onClick={() => { updateScenario('worst_case') }}
          className={clsx(
            "bg-gradient-to-b text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
            { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": configuration.scenario === 'worst_case' },
            { "from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": configuration.scenario !== 'worst_case' }
          )}
        >
          Worst Case
        </button>
      </div>
    </header>
  );
}