export default function PlayIcon({ className = "" }) {

  return (
    <svg height="512" className={className} viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
      <g id="_01-Play" data-name="01-Play">
        <path d="m26.17 12.37-17.17-9.92a3.23 3.23 0 0 0 -1.62-.45 3.38 3.38 0 0 0 -3.38 3.38v21.29a3.33 3.33 0 0 0 5.1 2.82l17.19-10.86a3.65 3.65 0 0 0 -.12-6.26z" />
      </g>
    </svg>
  )

}
