import { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import NextIcon from '../../layout/Icons/NextIcon';

const dropdownItems = [
  {
    "channelName": "Sponsored Ads: Wrestling Podcasts",
    "description": "Ads on popular wrestling podcasts featuring veteran wrestling personalities with for nostalgic fans",
    "percentageBudgetAllocated": 0,
    "totalReach": 350000,
    "frequency": 2,
    "clickThroughRate": 0.10,
    "conversionToFreeGameRate": 0.27
  },
  {
    "channelName": "YouTube Sponsored Ads on Wrestling Gaming Channels",
    "description": "Targeted ads on YouTube channels focused on wrestling games, reaching fans actively interested in the genre",
    "percentageBudgetAllocated": 0,
    "totalReach": 500000,
    "frequency": 1,
    "clickThroughRate": 0.12,
    "conversionToFreeGameRate": 0.25
  },
  {
    "channelName": "Wrestling Management Game Reviews on YouTube",
    "description": "Highly niche YouTube content featuring reviews of wrestling management games, reaching the most dedicated strategy fans",
    "percentageBudgetAllocated": 0,
    "totalReach": 80000,
    "frequency": 1,
    "clickThroughRate": 0.20,
    "conversionToFreeGameRate": 0.35
  },
  {
    "channelName": "Facebook Targeted Ads",
    "description": "Highly targeted ads on Facebook aimed at wrestling and gaming fans based on interest and behavior data",
    "percentageBudgetAllocated": 0,
    "totalReach": 500000,
    "frequency": 1,
    "clickThroughRate": 0.09,
    "conversionToFreeGameRate": 0.23
  },
  {
    "channelName": "Twitter Targeted Ads",
    "description": "Promoted tweets targeting wrestling fans and gaming enthusiasts with attention-grabbing visuals/CTAs",
    "percentageBudgetAllocated": 0,
    "totalReach": 400000,
    "frequency": 1,
    "clickThroughRate": 0.08,
    "conversionToFreeGameRate": 0.22
  },
  {
    "channelName": "Advertising on Subreddits (e.g. r/SquaredCircle)",
    "description": "Ads on wrestling subreddits, directly reaching dedicated fans who frequent these niche communities",
    "percentageBudgetAllocated": 0,
    "totalReach": 150000,
    "frequency": 1,
    "clickThroughRate": 0.11,
    "conversionToFreeGameRate": 0.29
  },
  {
    "channelName": "Sponsored Gaming Streams with Wrestling Personalities",
    "description": "Live-streamed sponsored sessions with wrestling influencers, showcasing gameplay to a captivated audience",
    "percentageBudgetAllocated": 0,
    "totalReach": 300000,
    "frequency": 1,
    "clickThroughRate": 0.14,
    "conversionToFreeGameRate": 0.30
  },
  {
    "channelName": "Influencer Campaign with Wrestling YouTubers",
    "description": "Sponsored content through wrestling-focused YouTubers creating engaging game reviews and gameplay videos",
    "percentageBudgetAllocated": 0,
    "totalReach": 250000,
    "frequency": 1,
    "clickThroughRate": 0.10,
    "conversionToFreeGameRate": 0.25
  },
  {
    "channelName": "Email Campaign to Subscribers of Wrestling Newsletters",
    "description": "Targeted email campaigns to subscribers of popular wrestling newsletters, promoting the game with download incentives",
    "percentageBudgetAllocated": 0,
    "totalReach": 250000,
    "frequency": 2,
    "clickThroughRate": 0.08,
    "conversionToFreeGameRate": 0.30
  },
  {
    "channelName": "Twitch Ads on Wrestling and Simulation Gaming Channels",
    "description": "In-stream ads on Twitch channels featuring wrestling and simulation gaming, capturing active viewers",
    "percentageBudgetAllocated": 0,
    "totalReach": 700000,
    "frequency": 2,
    "clickThroughRate": 0.07,
    "conversionToFreeGameRate": 0.22
  },
  {
    "channelName": "Sponsored Articles on Wrestling News Sites (e.g., Fightful, Wrestling Observer)",
    "description": "Sponsored articles and banner ads on wrestling news websites to reach fans browsing for updates",
    "percentageBudgetAllocated": 0,
    "totalReach": 400000,
    "frequency": 1,
    "clickThroughRate": 0.06,
    "conversionToFreeGameRate": 0.24
  },
  {
    "channelName": "Collaborative Content with Wrestling-themed Podcasts",
    "description": "Longer segments within popular wrestling podcasts where hosts discuss the game and its features",
    "percentageBudgetAllocated": 0,
    "totalReach": 200000,
    "frequency": 3,
    "clickThroughRate": 0.12,
    "conversionToFreeGameRate": 0.28
  },
  {
    "channelName": "Reddit Ads on Wrestling and Gaming Subreddits (e.g., r/WrestlingGames)",
    "description": "Highly targeted ads on niche subreddits focused on wrestling games engaging a dedicated audience",
    "percentageBudgetAllocated": 0,
    "totalReach": 100000,
    "frequency": 1,
    "clickThroughRate": 0.09,
    "conversionToFreeGameRate": 0.27
  },
  {
    "channelName": "Instagram Sponsored Stories on Wrestling Fan Pages",
    "description": "Sponsored story ads on Instagram fan pages focused on wrestling showcasing key gameplay",
    "percentageBudgetAllocated": 0,
    "totalReach": 600000,
    "frequency": 1,
    "clickThroughRate": 0.08,
    "conversionToFreeGameRate": 0.23
  },
  {
    "channelName": "Discord Community Sponsorship in Wrestling Gaming Servers",
    "description": "Sponsorships and shoutouts in Discord servers dedicated to wrestling games to build community trust",
    "percentageBudgetAllocated": 0,
    "totalReach": 100000,
    "frequency": 1,
    "clickThroughRate": 0.15,
    "conversionToFreeGameRate": 0.32
  },
  {
    "channelName": "TikTok Influencers in Wrestling and Gaming Niche",
    "description": "Short-form content collaboration with TikTok influencers in wrestling and gaming niches",
    "percentageBudgetAllocated": 0,
    "totalReach": 900000,
    "frequency": 1,
    "clickThroughRate": 0.11,
    "conversionToFreeGameRate": 0.26
  }
];

function useOutsideClickHandler(ref, setShowDropdownItems) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id !== 'toggleAddMarketingChannelButton'
      ) {
        setShowDropdownItems(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShowDropdownItems]);
}

function AddMarketingChannelDropdown({ addMarketingChannel }) {

  const [showDropdownItems, setShowDropdownItems] = useState(false);
  const dropdownWrapperRef = useRef(null);

  useOutsideClickHandler(
    dropdownWrapperRef,
    setShowDropdownItems,
  );

  const toggleMarketingChannelDropdown = () => {
    setShowDropdownItems((prev) => !prev);
  };

  return (
    <div className="relative flex">
      <button
        id="toggleAddMarketingChannelButton"
        onClick={toggleMarketingChannelDropdown}
        type="button"
        className={clsx(
          "flex flex-row items-center gap-3 text-white py-2 px-4 text-[15px] font-BarlowBold uppercase shadow-lg shadow-black/30",
          { "rounded-t-xl bg-[#1e2330]": showDropdownItems },
          { "rounded-xl bg-gradient-to-b hover:from-[#2a3148] hover:to-[#1d222e] from-[#212739] to-[#1e2330]": !showDropdownItems },
        )}
      >
        <span
          className={showDropdownItems ? 'rotate-90' : 'rotate-270'}
        >
          <NextIcon className="w-[14px] h-[14px] fill-gray-500" />
        </span>{' '}
        Add Marketing Channel
      </button>
      {showDropdownItems && (
        <div
          className="z-20 absolute top-[39px] w-[239px] shadow-lg overflow-hidden shadow-black/40 bg-gradient-to-b from-[#171b25] to-[#212739] rounded-b-xl border border-transparent border-t-gray-700"
          ref={dropdownWrapperRef}
        >
          <ul className="text-[14px] divide-y divide-gray-700 h-[500px] overflow-y-scroll">
            {dropdownItems.map((dropdownItem) => (
              <li
                key={crypto.randomUUID()}
                className="group flex items-center py-2.5 px-2 relative text-white hover:bg-gray-800 cursor-pointer"
                onClick={() => {
                  addMarketingChannel({
                    id: crypto.randomUUID(),
                    channelName: dropdownItem.channelName,
                    description: dropdownItem.description,
                    percentageBudgetAllocated: 0,
                    frequency: dropdownItem.frequency,
                    totalReach: dropdownItem.totalReach,
                    availableReach: 0,
                    impressions: 0,
                    clickThroughRate: dropdownItem.clickThroughRate,
                    visits: 0,
                    conversionToFreeGameRate: dropdownItem.conversionToFreeGameRate,
                    averageCostPerUser: 0
                  })
                  setShowDropdownItems(false);
                }}
              >
                <div className="group-hover:bg-blue-500 bg-transparent absolute left-0 top-0 bottom-0 w-1" />
                <span className="pl-4">{dropdownItem.channelName}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default AddMarketingChannelDropdown;