import { useEffect, useState, useCallback } from 'react';
import { useSimulation } from "../contexts/SimulationContext";

export default function HandleScenarioChange() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();

  const [currentScenario, setCurrentScenario] = useState('best_case');

  const setCompletionQuarter = useCallback(({ projectKey, quarter }) => {
    setConfiguration(prevConfig => {
      const updatedQuarters = {};

      for (let q in prevConfig.quarters) {
        const quarterNum = parseInt(q);
        updatedQuarters[quarterNum] = {
          ...prevConfig.quarters[quarterNum],
          [projectKey]: quarterNum >= quarter
        };
      }

      return {
        ...prevConfig,
        quarters: updatedQuarters
      };
    });
  }, [setConfiguration]);

  useEffect(() => {
    if (currentScenario !== configuration?.scenario) {
      switch (configuration?.scenario) {
        case 'best_case':
          setCompletionQuarter({ projectKey: "isFullGameReady", quarter: 4 });
          setCompletionQuarter({ projectKey: "isMultiplayerReady", quarter: 3 });
          setCompletionQuarter({ projectKey: "isMobileReady", quarter: 2 });
          break;
        case 'moderate_case':
          setCompletionQuarter({ projectKey: "isFullGameReady", quarter: 5 });
          setCompletionQuarter({ projectKey: "isMultiplayerReady", quarter: 4 });
          setCompletionQuarter({ projectKey: "isMobileReady", quarter: 3 });
          break;
        case 'worst_case':
          setCompletionQuarter({ projectKey: "isFullGameReady", quarter: 6 });
          setCompletionQuarter({ projectKey: "isMultiplayerReady", quarter: 5 });
          setCompletionQuarter({ projectKey: "isMobileReady", quarter: 4 });
          break;
        default:
          break;
      }
      setCurrentScenario(configuration?.scenario)
    }
  }, [configuration?.scenario])

  return null;
}
