import IntroImage from "../../images/intro.png";

export default function IntroductionPanel() {
  return (
    <section className="relative w-1/2 bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
      <section className="flex flex-row w-full justify-between items-center h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Introduction</h1>
      </section>
      <section className="flex flex-col h-full bg-[#080C0D] rounded-b-xl text-white">
        <div className="flex flex-col">
          <div className="p-5">
            <p className="pb-5">
              Welcome to the Ring Executive Pro Investor Portal. Ring Executive Pro is a wrestling management game built
              to fill a unique space in the market — much like Football Manager does for soccer.
            </p>

            <p className="pb-5">
              While the wrestling game market is largely dominated by WWE2K, an action-heavy title for casual play, we're here
              to cater to a different audience. Just as Football Manager has become a staple for those who want a more
              strategic experience alongside FIFA, we aim to captivate wrestling fans who crave thoughtful,
              management-driven gameplay.
            </p>
            {/* Welcome to the investor portal for Ring Executive Pro, a wrestling management game that
              is designed to fill a gap in the market, much like Football Manager does for soccer.
            </p>
            <p className="pb-5">
              The market is currently dominated by WWE2K, an action-packed casual fighting game.
              Football Manager has managed to carve out a niche for itself alongside
              FIFA and we want to do the same by targeting fans who prefer a strategic, thought
              driven experience.
            </p> */}
            <img src={IntroImage} alt="Market Positioning Intro" className="p-5 mb-5" />
            <h2 className="font-BarlowBold text-[20px] mb-3">Why Now?</h2>
            <p className="mb-4">
              The wrestling industry is experiencing a revival, with brands like AEW gaining prominence alongside
              the WWE. The growth is spurred by a generation of fans and curious nostalgic fans revisiting their
              childhoods. This presents us an opportunity to engage a large but niche audience that seeks deeper,
              more immersive gameplay.
            </p>
          </div>
          <div className="p-5 bg-gradient-to-b from-[#000000] from-0% to-75% to-[#080C0D]">
            <h2 className="font-BarlowBold text-[20px] mb-5">The Strategy</h2>
            <ul>
              <li className="flex flex-row items-start gap-5 mb-5">
                <span className="min-w-[30px] min-h-[30px] flex justify-center items-center hover:animate-pulse font-BarlowBlack rounded-full bg-gradient-to-b from-promoterblue1 to-promoterblue2">1</span>
                Launch the current in-development version of the game as "free-to-play" to build a loyal player base
              </li>
              <li className="flex flex-row items-start gap-5 mb-5">
                <span className="min-w-[30px] min-h-[30px] flex justify-center items-center hover:animate-pulse font-BarlowBlack rounded-full bg-gradient-to-b from-promoterblue1 to-promoterblue2">2</span>
                Generate revenue from that base by adding multiplayer and mobile support, whilst building up to an annual update sold at full
                price to our now sizeable player base
              </li>
              <li className="flex flex-row items-start gap-5">
                <span className="min-w-[30px] min-h-[30px] flex justify-center items-center hover:animate-pulse font-BarlowBlack rounded-full bg-gradient-to-b from-promoterblue1 to-promoterblue2">3</span>
                Exit by selling the game to a larger player, such as AEW, WWE, or a third-party publisher.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </section>
  );
}