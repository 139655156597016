export default function RewindIcon({ className = "" }) {

  return (
    <svg height="512" className={className} viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
      <g id="_05-Back" data-name="05-Back">
        <rect height="28" rx="3" width="8" x="2" y="2" />
        <path d="m26.84 2a3.17 3.17 0 0 0 -2.14.84l-11.64 10.75a3.27 3.27 0 0 0 0 4.82l11.64 10.75a3.16 3.16 0 0 0 5.3-2.32v-21.68a3.17 3.17 0 0 0 -3.16-3.16z" />
      </g>
    </svg>
  )

}
