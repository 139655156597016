import { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import NextIcon from '../../layout/Icons/NextIcon';
import { useSimulation } from "../../contexts/SimulationContext";

function useOutsideClickHandler(ref, setShowDropdownItems) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id !== 'toggleChangeCompletionQuarterDropdown'
      ) {
        setShowDropdownItems(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShowDropdownItems]);
}

const quarters = [
  { quarterId: 1, quarterName: 'Q1 2025' },
  { quarterId: 2, quarterName: 'Q2 2025' },
  { quarterId: 3, quarterName: 'Q3 2025' },
  { quarterId: 4, quarterName: 'Q4 2025' },
  { quarterId: 5, quarterName: 'Q1 2026' },
  { quarterId: 6, quarterName: 'Q2 2026' },
  { quarterId: 7, quarterName: 'Q3 2026' },
  { quarterId: 8, quarterName: 'Q4 2026' },
  { quarterId: 9, quarterName: 'Q1 2027' },
  { quarterId: 10, quarterName: 'Q2 2027' },
  { quarterId: 11, quarterName: 'Q3 2027' },
  { quarterId: 12, quarterName: 'Q4 2027' },
  { quarterId: 13, quarterName: 'Q1 2028' },
  { quarterId: 14, quarterName: 'Q1 2028' },
  { quarterId: 15, quarterName: 'Q1 2028' },
  { quarterId: 16, quarterName: 'Q1 2028' },
  { quarterId: 17, quarterName: 'Q1 2029' },
  { quarterId: 18, quarterName: 'Q2 2029' },
  { quarterId: 19, quarterName: 'Q3 2029' },
  { quarterId: 20, quarterName: 'Q4 2029' },
]

function ChangeCompletionQuarterDropdown({ projectKey = '', setCompletionQuarter }) {

  const [displayedCompletionPeriod, setDisplayedCompletionPeriod] = useState('Processing')
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const [showDropdownItems, setShowDropdownItems] = useState(false);
  const dropdownWrapperRef = useRef(null);

  function findFirstAvailableQuarter() {
    for (let quarter in configuration?.quarters) {
      if (configuration?.quarters[quarter][projectKey] === true) {
        return parseInt(quarter);
      }
    }
    return null;
  }

  useEffect(() => {
    if (projectKey === '') {
      setDisplayedCompletionPeriod('Q1 2025');
    } else {
      const firstQuarterProjectIsAvailableIndex = findFirstAvailableQuarter();
      const selectedQtr = quarters.find(quarter => {
        return quarter.quarterId === firstQuarterProjectIsAvailableIndex
      });
      if (selectedQtr) {
        setDisplayedCompletionPeriod(selectedQtr.quarterName)
      }
    }

  }, [configuration])

  useOutsideClickHandler(
    dropdownWrapperRef,
    setShowDropdownItems,
  );

  const toggleCompletionQuarterDropdown = () => {
    setShowDropdownItems((prev) => !prev);
  };

  return (
    <div className="relative flex">
      <button
        id="toggleChangeCompletionQuarterDropdown"
        onClick={() => {
          if (projectKey !== '') {
            toggleCompletionQuarterDropdown()
          }
        }}
        type="button"
        className={clsx(
          "flex flex-row items-center gap-3 text-white py-2 px-4 text-[15px] font-BarlowBold uppercase shadow-lg shadow-black/30",
          { "rounded-t-xl bg-[#1e2330]": showDropdownItems },
          { "rounded-xl bg-gradient-to-b hover:from-[#2a3148] hover:to-[#1d222e] from-[#212739] to-[#1e2330]": !showDropdownItems },
        )}
      >
        <span
          className={showDropdownItems ? 'rotate-90' : 'rotate-270'}
        >
          {(projectKey !== '') ? <NextIcon className="w-[14px] h-[14px] fill-gray-500" /> : null}
        </span>{' '}
        Completed: {displayedCompletionPeriod}
      </button>
      {/* {JSON.stringify(configuration, null, 2)} */}
      {showDropdownItems && (
        <div
          className="z-20 absolute top-[39px] w-full overflow-hidden shadow-lg shadow-black/40 bg-gradient-to-b from-[#171b25] to-[#212739] rounded-b-xl border border-transparent border-t-gray-700"
          ref={dropdownWrapperRef}
        >

          <ul className="text-[14px] h-[300px] overflow-y-scroll divide-y divide-gray-700">
            {quarters.map((quarter) => (
              <li
                key={crypto.randomUUID()}
                className="group flex items-center py-2.5 px-2 relative text-white hover:bg-gray-800 cursor-pointer"
                onClick={() => {
                  setCompletionQuarter({
                    projectKey,
                    quarter
                  })
                  setShowDropdownItems(false);
                }}
              >
                <div className="group-hover:bg-blue-500 bg-transparent absolute left-0 top-0 bottom-0 w-1" />
                <span className="pl-4">{quarter.quarterName}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ChangeCompletionQuarterDropdown;