import { useSimulation } from "../../contexts/SimulationContext";

export default function FunnelConfigurationPanel({ localConfiguration, setLocalConfiguration, setIsLocalExpensesDirty }) {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  return (

    <div className="w-1/2 bg-gradient-to-b from-transparent to-[#252A35] rounded-xl h-[500px] p-5 text-gray-500">
      <p className="mb-5">
        We have a tantalizing hook to offer people that enter our funnel. They can download a free version of Ring Executive Pro by
        registering an account with us.
      </p>

      <p className="mb-5">
        We want this free player base to enjoy the game but leave them with a feeling of wanting more. We will collect their
        requests and the features they're clamouring for the most will be developed into a new release which we can sell at full
        price. While we are developing the next title, we will modify our existing game and offer them both a mobile version and
        the ability to compete with others if they buy a monthly subscription.
      </p>

      <p className="mb-5">
        You can adjust the conversion rates of the stages in the funnel below. Obviously if the product is not available, the conversion
        rate is set to 0.
      </p>

      <div className="w-1/2">
        <p className="font-BarlowBold mb-2">Free User &raquo; Mobile Conversion Rate</p>
        <div className="text-gray-300">
          <input
            disabled={!localConfiguration?.isMobileReady}
            step="0.01"
            type="number"
            className="w-full px-2 py-1 border bg-inputbg border-inputborder rounded-lg shadow-forminput"
            value={!localConfiguration?.isMobileReady ? 0 : localConfiguration?.expenses.marketing.mobileConversionRate}
            onChange={(e) => {
              setLocalConfiguration(prev => {
                return {
                  ...prev,
                  expenses: {
                    ...prev.expenses,
                    marketing: {
                      ...prev.expenses.marketing,
                      mobileConversionRate: parseFloat(e.target.value)
                    }
                  }
                }
              });
              setIsLocalExpensesDirty(true);
            }}
          />
        </div>
      </div>
      <div className="w-1/2">
        <p className="font-BarlowBold mb-2">Free User &raquo; Multiplayer Conversion Rate</p>
        <div className="text-gray-300">
          <input
            disabled={!localConfiguration?.isMultiplayerReady}
            step="0.01"
            type="number"
            className="w-full px-2 py-1 border bg-inputbg border-inputborder rounded-lg shadow-forminput"
            value={!localConfiguration?.isMultiplayerReady ? 0 : localConfiguration?.expenses.marketing.multiplayerConversionRate}
            onChange={(e) => {
              setLocalConfiguration(prev => {
                return {
                  ...prev,
                  expenses: {
                    ...prev.expenses,
                    marketing: {
                      ...prev.expenses.marketing,
                      multiplayerConversionRate: parseFloat(e.target.value)
                    }
                  }
                }
              });
              setIsLocalExpensesDirty(true);
            }}
          />
        </div>
      </div>
      <div className="w-1/2">
        <p className="font-BarlowBold mb-2">Free User &raquo; Full Game Conversion Rate</p>
        <div className="text-gray-300">
          <input
            disabled={!localConfiguration?.isFullGameReady}
            step="0.01"
            type="number"
            className="w-full px-2 py-1 border bg-inputbg border-inputborder rounded-lg shadow-forminput"
            value={!localConfiguration?.isFullGameReady ? 0 : localConfiguration?.expenses.marketing.fullGameConversionRate}
            onChange={(e) => {
              setLocalConfiguration(prev => {
                return {
                  ...prev,
                  expenses: {
                    ...prev.expenses,
                    marketing: {
                      ...prev.expenses.marketing,
                      fullGameConversionRate: parseFloat(e.target.value)
                    }
                  }
                }
              });
              setIsLocalExpensesDirty(true);
            }}
          />
        </div>
      </div>
    </div>

  );
}