import { useState, useEffect, useCallback } from "react";
import { useSimulation } from "../../contexts/SimulationContext";
import { debounce } from "lodash";
import clsx from 'clsx';
import ServerHostingRow from "./ExpenseAllocationsPanel/ServerHostingRow";
import SoftwareLicensingRow from "./ExpenseAllocationsPanel/SoftwareLicensingRow";
import LegalRow from "./ExpenseAllocationsPanel/LegalRow";
import AccountingRow from "./ExpenseAllocationsPanel/Accounting";
import SupportRow from "./ExpenseAllocationsPanel/SupportRow";
import DevelopmentRow from "./ExpenseAllocationsPanel/DevelopmentRow";
import MarketingRow from "./ExpenseAllocationsPanel/MarketingRow";

const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(value);

export default function ExpenseAllocationsPanel() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const [currentReserve, setCurrentReserve] = useState(0);
  const [currentQuarterResult, setCurrentQuarterResult] = useState({ quarter: 'Unknown' })
  const [localConfiguration, setLocalConfiguration] = useState({});
  const [isLocalExpensesDirty, setIsLocalExpensesDirty] = useState(false);

  // set the local configuration every time the model is re-generated (or config is changed!)
  useEffect(() => {
    if (result) {
      const localConf = JSON.parse(JSON.stringify(configuration.quarters[String(configuration.current_quarter)]));

      const currentQuarterRes = result.revenue_over_time.find(entry => entry.quarterId === configuration.current_quarter);
      setCurrentQuarterResult(currentQuarterRes ?? { quarter: 'Unknown' })

      // the current reserve is calculated minus the expenses, so we need to add them back in
      const expenses = Object.values(localConf.expenses).reduce((sum, expense) => sum + parseInt(expense.budget), 0);
      const totalReservesPlusExpenses = result.final_reserve + expenses;

      setLocalConfiguration({ ...localConf });
      setCurrentReserve(totalReservesPlusExpenses);
    }
  }, [result, configuration]);

  const calculateTotalRemainingReserve = useCallback(() => {
    if (localConfiguration.hasOwnProperty('expenses')) {
      const startingReserve = currentReserve;
      const currentExpenses = Object.values(localConfiguration.expenses).reduce(
        (sum, expense) => sum + parseInt(expense.budget),
        0
      );
      return startingReserve - currentExpenses >= 0 ? startingReserve - currentExpenses : 0;
    } else {
      return 0;
    }
  }, [currentReserve, localConfiguration]);

  const updateBudget = (category, newValue) => {
    const budgetValue = parseInt(newValue);

    if (budgetValue === currentReserve) {
      setLocalConfiguration((prevLocalConfig) => {
        const updatedLocalConfig = { ...prevLocalConfig };
        Object.keys(updatedLocalConfig.expenses).forEach((key) => {
          updatedLocalConfig.expenses[key].budget = key === category ? budgetValue : 0;
        });
        return updatedLocalConfig;
      });
    } else {
      setLocalConfiguration((prevLocalConfig) => {
        const updatedLocalConfig = { ...prevLocalConfig };
        updatedLocalConfig.expenses[category].budget = budgetValue;
        let remainingReserve = currentReserve - budgetValue;
        Object.keys(updatedLocalConfig.expenses).forEach((key) => {
          if (key !== category) {
            updatedLocalConfig.expenses[key].budget = Math.min(updatedLocalConfig.expenses[key].budget, remainingReserve);
            remainingReserve -= updatedLocalConfig.expenses[key].budget;
          }
        });

        return updatedLocalConfig;
      });
    }
    setIsLocalExpensesDirty(true);
  };

  const debouncedUpdateBudget = debounce((category, newValue) => {
    updateBudget(category, parseInt(newValue));
  }, 0);

  const applyChanges = () => {
    setConfiguration((prevConfig) => {
      const updatedConfig = { ...prevConfig };
      updatedConfig.quarters[String(prevConfig.current_quarter)] = localConfiguration;
      return updatedConfig;
    });
    setIsLocalExpensesDirty(false);
  };

  const removeDevelopmentResource = (developmentResource) => {
    localConfiguration.expenses.development.resources = localConfiguration.expenses.development.resources.filter(resource => resource.id !== developmentResource.id);
    const currentDevelopmentExpenses = parseInt(localConfiguration.expenses.development.budget);
    updateBudget('development', currentDevelopmentExpenses - developmentResource.salary);
  }

  const addDevelopmentResource = (developmentResource) => {
    if (developmentResource.salary > currentReserve) {
      alert('You do not have enough budget to add this resource');
      return;
    }
    localConfiguration.expenses.development.resources.push(developmentResource);
    const currentDevelopmentExpenses = parseInt(localConfiguration.expenses.development.budget);
    updateBudget('development', currentDevelopmentExpenses + developmentResource.salary);
  }

  const removeSupportResource = (supportResource) => {
    localConfiguration.expenses.support.resources = localConfiguration.expenses.support.resources.filter(resource => resource.id !== supportResource.id);
    const currentSupportExpense = parseInt(localConfiguration.expenses.support.budget);
    updateBudget('support', currentSupportExpense - supportResource.salary);
  }

  const addSupportResource = (supportResource) => {
    if (supportResource.salary > currentReserve) {
      alert('You do not have enough budget to add this resource');
      return;
    }
    localConfiguration.expenses.support.resources.push(supportResource);
    const currentSupportExpense = parseInt(localConfiguration.expenses.support.budget);
    updateBudget('support', currentSupportExpense + supportResource.salary);
  }

  const removeMarketingResource = (marketingResource) => {
    localConfiguration.expenses.marketing.resources = localConfiguration.expenses.marketing.resources.filter(resource => resource.id !== marketingResource.id);
    const currentMarketingExpense = parseInt(localConfiguration.expenses.marketing.budget);
    updateBudget('marketing', currentMarketingExpense - marketingResource.salary);
  }

  const addMarketingResource = (marketingResource) => {
    if (marketingResource.salary > currentReserve) {
      alert('You do not have enough budget to add this resource');
      return;
    }
    localConfiguration.expenses.marketing.resources.push(marketingResource);
    const currentMarketingExpense = parseInt(localConfiguration.expenses.marketing.budget);
    updateBudget('marketing', currentMarketingExpense + marketingResource.salary);
  }

  return result ? (
    <section className="space-y-6">
      <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
        <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
          <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Expense Allocation for {currentQuarterResult?.quarter ?? 'Unknown'}</h1>
          <button
            onClick={() => { applyChanges() }}
            className={clsx(
              "bg-gradient-to-b text-white py-1 px-3 rounded-lg shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
              { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": isLocalExpensesDirty },
              { "opacity-45 from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": !isLocalExpensesDirty }
            )}
            disabled={!isLocalExpensesDirty}
          >
            Apply Changes
          </button>
        </section>
        <section className="flex p-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">
          <p className="mb-5">Allocate expenses for {currentQuarterResult?.quarter ?? 'Unknown'}. Unallocated funds go into Reserve Cash automatically.</p>
          <div className="space-y-4">
            {localConfiguration.hasOwnProperty('expenses') && (
              <>
                <DevelopmentRow
                  value={localConfiguration.expenses.development}
                  max={currentReserve}
                  updateFunc={debouncedUpdateBudget}
                  removeDevelopmentResource={removeDevelopmentResource}
                  addDevelopmentResource={addDevelopmentResource}
                />

                <MarketingRow
                  value={localConfiguration.expenses.marketing}
                  max={currentReserve}
                  updateFunc={debouncedUpdateBudget}
                  removeMarketingResource={removeMarketingResource}
                  addMarketingResource={addMarketingResource}
                />

                <SupportRow
                  value={localConfiguration.expenses.support}
                  max={currentReserve}
                  updateFunc={debouncedUpdateBudget}
                  removeSupportResource={removeSupportResource}
                  addSupportResource={addSupportResource}
                />

                <AccountingRow
                  value={localConfiguration.expenses.accounting.budget}
                  max={currentReserve}
                  updateFunc={debouncedUpdateBudget}
                />

                <LegalRow
                  value={localConfiguration.expenses.legal.budget}
                  max={currentReserve}
                  updateFunc={debouncedUpdateBudget}
                />

                <SoftwareLicensingRow
                  value={localConfiguration.expenses.software_licenses.budget}
                  max={currentReserve}
                  updateFunc={debouncedUpdateBudget}
                />

                <ServerHostingRow
                  value={localConfiguration.expenses.server_hosting.budget}
                  max={currentReserve}
                  updateFunc={debouncedUpdateBudget}
                />

                <div className="flex flex-col gap-4 items-center justify-between border-t border-slate-800 pt-3">
                  <div className="w-full flex justify-between p-5 bg-[#252A35]/40 rounded-lg">
                    <span className="text-white w-[200px] font-semibold">Total Quarterly Expense</span>
                    <span className="pl-5 text-gray-300 font-semibold">{formatCurrency(Object.values(localConfiguration.expenses).reduce(
                      (sum, expense) => sum + parseInt(expense.budget),
                      0
                    ))}</span>
                  </div>
                  <div className="w-full flex justify-between p-5 bg-[#252A35]/40 rounded-lg">
                    <span className="text-white w-[200px] font-semibold">Reserve Cash</span>
                    <span className="pl-5 text-gray-300 font-semibold">{formatCurrency(calculateTotalRemainingReserve())}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </section>
    </section>
  ) : null;
}