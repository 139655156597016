import { useSimulation } from "../../contexts/SimulationContext";

export default function RevenueBreakdownPanel() {
  const { configuration, result } = useSimulation();

  const revenueData = result?.revenue_over_time || [];

  const revenueSources = [
    { name: "Base Game Sales", key: "fullGameSales", userKey: "fullGameUsers" },
    { name: "Mobile Game Sales", key: "mobileGameSales", userKey: "mobileUsers" },
    { name: "Multiplayer Subscriptions", key: "multiplayerSubscriptions", userKey: "multiplayerUsers" }
  ];

  return (
    <section className="space-y-6">
      <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50 overflow-hidden">
        <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
          <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Revenue Breakdown by Stream</h1>
        </section>
        <section className="flex p-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white overflow-x-auto">
          <table className="w-full text-left table-auto text-gray-300 min-w-[800px]">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="min-w-[250px] w-full py-3 text-sm uppercase sticky left-0 bg-[#080C0D]">Revenue Source</th>
                {revenueData.map((data, index) => (
                  <th key={index} className="min-w-[100px] py-3 text-sm uppercase">{data.quarter}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {revenueSources.map((source, sourceIndex) => (
                <tr key={sourceIndex} className="border-b border-gray-700">
                  <td className="py-3 sticky left-0 bg-[#080C0D] font-semibold">{source.name}</td>
                  {revenueData.map((data, quarterIndex) => (
                    <td key={quarterIndex} className="py-3">
                      {(source.key === 'fullGameSales') && (
                        <>
                          <span>${(data[source.key] * configuration.quarters[configuration.current_quarter].income.yearly_release.pricing)?.toLocaleString()}</span>
                          <span className="block text-xs text-gray-600">{data.users[source.userKey]?.toLocaleString() || 0}</span>
                        </>
                      )}
                      {(source.key === 'mobileGameSales') && (
                        <>
                          <span>${(data[source.key] * configuration.quarters[configuration.current_quarter].income.mobile_app.pricing)?.toLocaleString()}</span>
                          <span className="block text-xs text-gray-600">{data.users[source.userKey]?.toLocaleString() || 0}</span>
                        </>
                      )}
                      {(source.key === 'multiplayerSubscriptions') && (
                        <>
                          <span>${(data[source.key] * configuration.quarters[configuration.current_quarter].income.multiplayer_subscriptions.pricing)?.toLocaleString()}</span>
                          <span className="block text-xs text-gray-600">{data.users[source.userKey]?.toLocaleString() || 0}</span>
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </section>
    </section>
  );
}
