
export default function PlusIcon({ className = "" }) {

  return (
    <svg enableBackground="new 0 0 512 512" height="512" className={className} viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path clip-rule="evenodd" d="m231.25 365.96v-85.18h-85.21c-32.54 0-32.54-49.52 0-49.52h85.21v-85.21c0-32.54 49.52-32.54 49.52 0v85.21h85.18c32.54 0 32.54 49.52 0 49.52h-85.18v85.18c0 32.54-49.52 32.54-49.52 0zm24.75 146.04c141.39 0 256-114.6 256-256 0-141.39-114.61-256-256-256s-256 114.61-256 256 114.61 256 256 256z" fill-rule="evenodd"></path>
      </g>
    </svg>
  )

}