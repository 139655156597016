import { useSimulation } from "../contexts/SimulationContext";
import ProjectionsHeader from "./Projections/ProjectionsHeader";
import OverviewMetricsPanel from "./Projections/OverviewMetricsPanel";
import CashFlowForecastPanel from "./Projections/CashFlowForecastPanel";
import RevenueMobileVersionPanel from "./Projections/RevenueMobileVersionPanel";
import RevenueMultiplayerSubscriptionsPanel from "./Projections/RevenueMultiplayerSubscriptionsPanel";
import RevenueYearlyReleasePanel from "./Projections/RevenueYearlyReleasePanel";
import ExpenseAllocationsPanel from "./Projections/ExpenseAllocationsPanel";
import RevenueBreakdownPanel from "./Projections/RevenueBreakdownPanel";
import BurnRateRunwayPanel from "./Projections/BurnRateRunwayPanel";

export default function Projections() {

  const { configuration, setConfiguration, result, runSimulation } = useSimulation();

  return (
    <div className="flex flex-col gap-5">
      <ProjectionsHeader />
      <div className="flex flex-row gap-5">
        <OverviewMetricsPanel />
        <CashFlowForecastPanel />
      </div>
      <div className="flex flex-row gap-5">
        <BurnRateRunwayPanel />
        <RevenueYearlyReleasePanel />
        <RevenueMobileVersionPanel />
        <RevenueMultiplayerSubscriptionsPanel />
      </div>
      <RevenueBreakdownPanel />
      <ExpenseAllocationsPanel />
    </div>
  );
}