import AddSupportResourceDropdown from "./SupportRow/AddSupportResourceDropdown";

const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(value);

export default function SupportRow({ value, max, updateFunc, removeSupportResource, addSupportResource }) {

  return (
    <div className="p-5 bg-[#252A35]/40 rounded-lg">
      <div className="flex items-center justify-between">
        <span className="pr-5 w-[150px] text-white">Support</span>
        <input
          disabled="disabled"
          id="range"
          type="range"
          step="1000"
          min="0"
          max={max}
          value={value.budget}
          onChange={(e) => updateFunc("support", e.target.value)}
          className="block w-full px-3 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md"
        />
        <span className="pl-5 w-[100px] text-right text-gray-300">{formatCurrency(value.budget)}</span>
      </div>
      <div className="flex pl-[135px] bg-[#111111] mt-5 px-5 py-2 rounded-xl items-center justify-between">
        <table className="w-full text-left table-auto text-gray-300">
          <thead>
            <tr className="border-b border-gray-700">
              <th className="py-3 text-sm uppercase">Role</th>
              <th className="py-3 text-sm uppercase">Description</th>
              <th className="py-3 text-sm uppercase">Salary</th>
              <th className="py-3 text-sm uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {value.resources.map(resource => {
              return (
                <tr key={resource.id}>
                  <td className="py-3">{resource.role}</td>
                  <td className="py-3">{resource.description}</td>
                  <td className="py-3">{formatCurrency(resource.salary)}</td>
                  <td className="py-3">
                    <button
                      onClick={() => { removeSupportResource(resource) }}
                      className="bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
                    >
                      Remove Resource
                    </button>
                  </td>
                </tr>
              )
            })}
            <tr>
              <td className="py-3" colSpan={3}>
                <AddSupportResourceDropdown addSupportResource={addSupportResource} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}