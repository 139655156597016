import { Link } from 'react-router-dom';
import GenZModel from '../../images/genz.png';
import MillenialModel from '../../images/millenials.png';

export default function TargetAudiencePanel() {
  return (
    <section className="relative w-1/2 bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[18px] font-BarlowBold text-[#A5A5A5]">Marketing Strategy and User Acquisition Projections</h1>
      </section>
      <section className="flex p-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white space-y-6">

        <p>
          We're keeping our <a target="blank" className="underline" href="https://www.youtube.com/watch?v=E5_-6KhGLR8">current marketing efforts</a> simple enough for casual fans to enjoy, but with extra layers to keep
          hardcore fans hooked. The plan is to mix traditional targeted marketing with standout creative work and hands-on efforts that
          don't scale!
        </p>

        <div className="flex flex-col space-y-4">
          <h2 className="relative -top-[5px] text-[20px] font-BarlowBold text-white">We Know Our Target Audience and How to Reach Them</h2>

          <div className="flex w-full flex-col md:flex-row md:justify-between gap-5 space-y-5 md:space-y-0">
            <div className="w-1/2 flex flex-row p-4 bg-[#111722]/50 rounded-lg shadow-lg border border-[#2F3341] bg-contain bg-no-repeat bg-right" style={{
              backgroundImage: `url(${GenZModel})`,
            }}>
              <div className="w-full flex flex-col">
                <h3 className="text-lg font-BarlowBold text-promoterblue1">Gen-Z Viewers</h3>
                <p className="w-3/4 text-gray-300 mt-2">New fans attracted by current storylines discovered via platforms like TikTok and YouTube</p>
                <p className="w-3/4 text-sm text-gray-400 mt-6">Doing things that don't scale...</p>
                <div className="mt-3 text-sm text-gray-400 space-y-2">
                  <a
                    href="https://www.youtube.com/shorts/zgE6JQsKZF4"
                    className="flex flex-row gap-2 items-start bg-gradient-to-l from-[#111722] to-transparent hover:from-promoterblue1/100 hover:to-promoterblue1/50 text-white px-2 py-1 rounded-lg drop-shadow-md"
                    target="blank"
                  >
                    <span className="text-promoterblue1 mr-1">✦</span> Twitch streams of wrestling personalities playing the game with fans
                  </a>
                  <a
                    href="https://www.tiktok.com/@_upupdwndwn/video/7239733642216377642"
                    className="flex flex-row gap-2 items-start bg-gradient-to-l from-[#111722] to-transparent hover:from-promoterblue1/100 hover:to-promoterblue1/50 text-white px-2 py-1 rounded-lg drop-shadow-md"
                    target="blank"
                  >
                    <span className="text-promoterblue1 mr-1">✦</span> Wrestlers reacting to their stats in the game on TikTok
                  </a>
                </div>
              </div>
            </div>
            <div className="w-1/2 flex flex-col items-end text-right p-4 bg-[#111722]/50 rounded-lg shadow-lg border border-[#2F3341] bg-contain bg-no-repeat bg-left" style={{
              backgroundImage: `url(${MillenialModel})`,
            }}>
              <div className="w-full flex flex-col items-end">
                <h3 className="text-lg font-BarlowBold text-[#ff8c42]">25-55 Year Old Males</h3>
                <p className="w-3/4 text-gray-300 mt-2">Returning older fans with disposable income, nostalgic about their childhood interest</p>
                <p className="w-full text-sm text-gray-400 mt-6">Doing things that don't scale...</p>
                <div className="mt-3 text-sm text-gray-400 space-y-2">
                  <a
                    href="https://www.youtube.com/watch?v=J4biqbIJQrE"
                    className="flex flex-row gap-2 items-start bg-gradient-to-l from-[#111722] to-transparent hover:from-[#ff8c42]/25 hover:to-[#ff8c42]/100 text-white px-2 py-1 rounded-lg drop-shadow-md"
                    target="blank"
                  >
                    Advertising on podcasts with veteran wrestling personalities <span className="relative top-[1px] text-[#ff8c42]">✦</span>
                  </a>
                  <a
                    href="https://www.reddit.com/r/SquaredCircle/comments/ll5khh/cartoon_map_of_the_old_wrestling_territories_and/"
                    className="flex flex-row gap-2 justify-end items-start bg-gradient-to-l from-[#111722] to-transparent hover:from-[#ff8c42]/25 hover:to-[#ff8c42]/100 text-white px-2 py-1 rounded-lg drop-shadow-md"
                    target="blank"
                  >
                    Viral long-form blogs, illustrations and video short <span className="relative top-[1px] text-[#ff8c42]">✦</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          <h2 className="text-xl font-BarlowBold mb-3 text-white">Our Marketing Goals</h2>
          <ul className="flex flex-row gap-x-5 items-start">
            <li className="w-1/3 flex flex-row items-start gap-x-5 mb-5">
              <span className="min-w-[30px] min-h-[30px] relative top-[5px] flex justify-center items-center hover:animate-pulse font-BarlowBlack rounded-full bg-gradient-to-b from-promoterblue1 to-promoterblue2">1</span>
              Control of people's attention is valuable. Our goal is to attract a large user base so we can sell the company or monetize the attention we've commanded
            </li>
            <li className="w-1/3 flex flex-row items-start gap-x-5 mb-5">
              <span className="min-w-[30px] min-h-[30px] relative top-[5px] flex justify-center items-center hover:animate-pulse font-BarlowBlack rounded-full bg-gradient-to-b from-promoterblue1 to-promoterblue2">2</span>
              Marketing isn't just a money pit - it's as crucial as balancing the game or writing code and requires the same level of focus and strategy
            </li>
            <li className="w-1/3 flex flex-row items-start gap-x-5">
              <span className="min-w-[30px] min-h-[30px] relative top-[5px] flex justify-center items-center hover:animate-pulse font-BarlowBlack rounded-full bg-gradient-to-b from-promoterblue1 to-promoterblue2">3</span>
              We recognize people often have one passion they're deeply invested in. We want to creatively engage them during lulls in their passion's content cycle
            </li>
          </ul>
        </div>

        <div className="flex flex-col space-y-4">
          <h2 className="text-xl font-BarlowBold text-white">Our Killer Proposition</h2>
          <p className="text-gray-300">
            We're offering a completely free game as the entry to our funnel. This ensures we have a captive audience that we can later monetize through paid add-ons and a full priced game.
          </p>
        </div>

        <div className="mt-6 w-full">
          <Link
            to="/user_acquisition"
            className="block text-center w-full bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
          >
            User Acquisition Projections
          </Link>
        </div>
      </section>
    </section>
  );
}
