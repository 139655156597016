export default function VideoIcon({ className = "" }) {
  return (
    <svg version="1.1" className={className} enableBackground="new 0 0 72 72" height="512" viewBox="0 0 72 72" width="512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <g>
            <g>
              <path d="m64.9 6.7h-57.8c-2.3 0-4.1 1.8-4.1 4v37.5c0 2.2 1.8 4.1 4.1 4.1h57.9c2.2 0 4.1-1.8 4.1-4.1v-37.5c-.1-2.2-1.9-4-4.2-4zm-28.9 39.3c-9.1 0-16.6-7.4-16.6-16.6 0-9.1 7.4-16.6 16.6-16.6 9.1 0 16.6 7.4 16.6 16.6s-7.5 16.6-16.6 16.6z"></path>
            </g>
            <g>
              <g>
                <path d="m43.5 29.5c0 1-.5 1.9-1.4 2.5l-8.3 5c-.5.3-1 .4-1.5.4s-1-.1-1.4-.4c-.9-.5-1.5-1.5-1.5-2.5v-10c0-1 .5-2 1.5-2.5.9-.5 2-.5 2.9 0l8.3 5c.9.5 1.4 1.4 1.4 2.5z"></path>
              </g>
            </g>
          </g>
        </g>
        <g>
          <path d="m69 61.5c0 .5-.4 1-1 1h-47.9c-.6 1.7-2.4 2.9-4.7 2.9-2.2 0-4.1-1.2-4.7-2.9h-5.8c-.6 0-1-.5-1-1 0-.6.4-1 1-1h5.8c.5-1.7 2.4-2.9 4.7-2.9s4.1 1.2 4.7 2.9h47.9c.6 0 1 .4 1 1z"></path>
        </g>
      </g>
    </svg>
  );
}