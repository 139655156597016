import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const mainContainer = document.getElementById("main-container");
      if (mainContainer) {
        mainContainer.scrollTo(0, 0)
      } else {
        window.scrollTo(0, 0);
      }
    }, 0);
  }, [pathname]);

  return null;
}
