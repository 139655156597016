import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useSimulation } from "../../contexts/SimulationContext";
import { JsonEditor } from 'json-edit-react'

export default function SideBar() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();

  const [tempState, setTempState] = useState('');

  useEffect(() => {
    setTempState(configuration)
  }, [configuration])

  const loadChanges = () => {
    setConfiguration(tempState);
  };

  const exportConfig = () => {
    const fileContents = JSON.stringify(configuration, null, 2);

    const blob = new Blob([fileContents], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'SimulationData.json';
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <div class="transition-all text-gray-500 duration-1000 flex flex-col p-5 w-[600px] h-full items-start gap-4 border-t border-gray-800 bg-gradient-to-b from-[#1d222f] to-[#111111]">
      <div class="flex rounded-lg w-full justify-start bg-promoterblue2 text-white text-sm font-bold px-4 py-3" role="alert">
        <svg class="fill-white w-4 h-4 mr-2 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" /></svg>
        <p>If you make changes below, make sure to load them into the model before trying to export</p>
      </div>
      <h2 className="font-BarlowBold text-white text-[18px]">Load and Save Model Configuration</h2>
      <p>When you modify any values in the portal, it updates the base configuration shown below. You can also use this feature to save changes you've made to a model to reload later.</p>
      <div className="h-full overflow-auto">
        <JsonEditor
          data={configuration}
          enableClipboard={false}
          restrictDelete={true}
          showCollectionCount={false}
          stringTruncate={75}
          theme="githubDark"
          rootFontSize="12px"
          onUpdate={({ newData }) => {
            setTempState(newData)
          }}
        />
      </div>
      <div className="w-full flex flex-col gap-3">
        <button
          onClick={() => { loadChanges() }}
          className={clsx(
            "w-full bg-gradient-to-b text-white py-2 px-3 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
            { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": tempState !== '' },
            { "opacity-75 from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": tempState === '' }
          )}
          disabled={tempState === ''}
        >
          Load Changes into the Model
        </button>
        <button
          onClick={() => { exportConfig() }}
          className={clsx(
            "w-full bg-gradient-to-b text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
            { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": tempState !== '' },
            { "opacity-75 from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": tempState === '' }
          )}
          disabled={tempState === ''}
        >
          Export Configuration
        </button>
      </div>
    </div>
  )
}