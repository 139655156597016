import { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import NextIcon from '../../../../layout/Icons/NextIcon';

const dropdownItems = [
  { role: 'Frontend Developer', description: '', salary: 4000 },
  { role: 'Backend Developer', description: '', salary: 6900 },
  { role: 'Mobile Developer', description: '', salary: 7200 },
  { role: 'UI / UX Designer', description: '', salary: 4800 },
  { role: 'QA Tester', description: '', salary: 3000 },
  { role: 'Game Designer', description: '', salary: 6000 },
];

function useOutsideClickHandler(ref, setShowDropdownItems) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id !== 'toggleAddDevelopmentResourceButton'
      ) {
        setShowDropdownItems(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShowDropdownItems]);
}

function AddDevelopmentResourceDropdown({ addDevelopmentResource }) {

  const [showDropdownItems, setShowDropdownItems] = useState(false);
  const dropdownWrapperRef = useRef(null);

  useOutsideClickHandler(
    dropdownWrapperRef,
    setShowDropdownItems,
  );

  const toggleDevelopmentResourceDropdown = () => {
    setShowDropdownItems((prev) => !prev);
  };

  return (
    <div className="relative flex">
      <button
        id="toggleAddDevelopmentResourceButton"
        onClick={toggleDevelopmentResourceDropdown}
        type="button"
        className={clsx(
          "flex flex-row items-center gap-3 text-white py-2 px-4 font-BarlowBold uppercase shadow-lg shadow-black/30",
          { "rounded-t-xl bg-[#1e2330]": showDropdownItems },
          { "rounded-xl bg-gradient-to-b hover:from-[#2a3148] hover:to-[#1d222e] from-[#212739] to-[#1e2330]": !showDropdownItems },
        )}
      >
        <span
          className={showDropdownItems ? 'rotate-90' : 'rotate-270'}
        >
          <NextIcon className="w-[14px] h-[14px] fill-gray-500" />
        </span>{' '}
        Add Development Resource
      </button>
      {showDropdownItems && (
        <div
          className="z-20 absolute top-[41px] w-[280px] overflow-hidden shadow-lg shadow-black/40 bg-gradient-to-b from-[#171b25] to-[#212739] rounded-b-xl border border-transparent border-t-gray-700"
          ref={dropdownWrapperRef}
        >
          <ul className="text-[14px] divide-y divide-gray-700">
            {dropdownItems.map((dropdownItem) => (
              <li
                key={dropdownItem.key}
                className="group flex items-center py-2.5 px-2 relative text-white hover:bg-gray-800 cursor-pointer"
                onClick={() => {
                  addDevelopmentResource({
                    id: crypto.randomUUID(),
                    role: dropdownItem.role,
                    description: dropdownItem.description,
                    salary: dropdownItem.salary,
                  })
                  setShowDropdownItems(false);
                }}
              >
                <div className="group-hover:bg-blue-500 bg-transparent absolute left-0 top-0 bottom-0 w-1" />
                <span className="pl-4">{dropdownItem.role}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default AddDevelopmentResourceDropdown;