import React from 'react';
import { useSimulation } from "../../contexts/SimulationContext";

const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(value);


const formatStrategy = (value) => {
  switch (value) {
    case "moderate_case":
      return 'Moderate Case';
    case "worst_case":
      return 'Worst Case';
    case "best_case":
    default:
      return 'Best Case'
  }
}

export default function ViewOfferModal({ buyer, onClose, onAccept, calculateExitValuation }) {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();

  const {
    exitValuation,
    multiple,
    average12MonthRevenue,
    scenario
  } = calculateExitValuation(buyer, result, configuration.scenario);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-[#111722] rounded-lg shadow-xl border border-[#2F3341] p-6 rounded-lg text-white w-[400px]">
        <h2 className="text-[20px] font-BarlowBold mb-4">Offer from {buyer.name}</h2>
        <p className="mb-4 text-gray-400">{buyer.description}</p>
        <p className="font-BarlowBold text-lg mb-4">Proposed Exit Valuation: <span className="text-textactive">{formatCurrency(exitValuation)}</span></p>
        <p className="text-gray-500 text-[14px] mb-6">This valuation is based on <span class="text-gray-400">{multiple}x</span> the average of the last 12 month's revenue ({formatCurrency(average12MonthRevenue)}). The multiple is based on the current scenario: <span class="text-gray-400">{formatStrategy(scenario)}</span></p>
        <div className="flex justify-between gap-3">
          <button
            className="w-1/2 bg-gradient-to-b from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4 text-white py-2 px-4 mt-auto rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
            onClick={onAccept}
          >
            Accept Offer
          </button>
          <button
            className="w-1/2 bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 mt-auto rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
            onClick={onClose}
          >
            Continue Looking
          </button>
        </div>
      </div>
    </div>
  );
}