import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSimulation } from "../../contexts/SimulationContext";
import BestCaseScenario from "../../images/best_case.svg";
import ModerateCaseScenario from "../../images/moderate_case.svg";
import WorstCaseScenario from "../../images/worst_case.svg";
import clsx from 'clsx';
import { potentialBuyers } from '../ExitStrategy'

const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(value);


export default function ExitStrategyPanel() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const navigate = useNavigate();

  const updateScenario = (newScenario) => {
    setConfiguration((prevConfig) => ({
      ...prevConfig,
      scenario: newScenario
    }));
  };

  const freeUsers = result?.current_users.free || 0;
  const mobileUsers = result?.current_users.mobile || 0;
  const multiplayerUsers = result?.current_users.multiplayer || 0;
  const fullGameUsers = result?.current_users.fullGame || 0;
  const totalUsers = mobileUsers + multiplayerUsers + fullGameUsers;
  const arpu = totalUsers > 0 ? result?.total_revenue / totalUsers : 0;
  const mobileRevenue = result?.revenue_over_time.reduce(
    (sum, quarter) => {
      return sum + (quarter.mobileGameSales * configuration?.quarters[quarter.quarterId].income.mobile_app.pricing)
    },
    0
  );
  const mobileARPU = mobileUsers > 0 ? mobileRevenue / mobileUsers : 0;

  const checkInterestConditions = useCallback((buyer) => {
    if (result === null) {
      return false;
    }

    const { userBase = null, ARPU = null, mobileUserBase = null, mobileARPU = null } = buyer.interestCondition;
    const { current_users, total_revenue } = result;

    const totalUsers = current_users.free + current_users.mobile + current_users.multiplayer + current_users.fullGame;
    const overallARPU = totalUsers > 0 ? total_revenue / totalUsers : 0;

    const mobileRevenue = result.revenue_over_time.reduce((sum, quarter) => {
      return sum + (quarter.mobileGameSales * configuration?.quarters[quarter.quarterId].income.mobile_app.pricing)
    }, 0);
    const actualMobileARPU = current_users.mobile > 0 ? mobileRevenue / current_users.mobile : 0;

    const isInterested =
      (userBase === null || current_users.free >= userBase) &&
      (ARPU === null || overallARPU >= ARPU) &&
      (mobileUserBase === null || current_users.mobile >= mobileUserBase) &&
      (mobileARPU === null || actualMobileARPU >= mobileARPU);

    console.log(`${buyer.name}: ${isInterested}
      - Free Users Expected: ${userBase ?? "N/A"}, Acquired: ${current_users.free}
      - ARPU Expected: ${ARPU ?? "N/A"}, Achieved: ${overallARPU.toFixed(2)}
      - Mobile Users Expected: ${mobileUserBase ?? "N/A"}, Acquired: ${current_users.mobile}
      - Mobile ARPU Expected: ${mobileARPU ?? "N/A"}, Achieved: ${actualMobileARPU.toFixed(2)}
    `);

    return isInterested;
  }, [configuration, result]);

  return (
    <section className="relative w-1/2 bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50 overflow-hidden">
      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">TODO: Exit Strategy</h1>
        <div className="gap-3 flex flex-row items-center">
          <Link
            to="/exit_strategy"
            className="bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-1 px-3 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
          >
            Explore Exit Scenarios
          </Link>
        </div>
      </section>
      <section className="flex flex-col h-full bg-[#080C0D] divide-slate-800 gap-5 divide-y rounded-b-xl text-white">

        <div className="flex flex-col px-5 py-3">
          <div className="bg-gradient-to-t from-[#252A35]/40 to-transparent py-5 px-5 rounded-xl space-y-4">
            <h3 className="text-xl font-semibold">Current Applied Strategy</h3>
            <div className="flex flex-row gap-5 items-center">
              {configuration.scenario === 'best_case' ? (
                <>
                  <img src={BestCaseScenario} alt="Best Case Scenario Icon" className="inline-block w-[50px] h-[50px] ml-2" />
                  <p className="text-gray-300">
                    The current strategy is based on the <span className="text-textactive">Best Case Scenario</span>, where we achieve rapid user growth, expansion into mobile, and success in multiplayer monetization. This increases the likelihood of a potential acquisition or IP licensing deal by Q2 2027.
                  </p>
                </>
              ) : null}
              {configuration.scenario === 'moderate_case' ? (
                <>
                  <img src={ModerateCaseScenario} alt="Moderate Scenario Icon" className="inline-block w-[50px] h-[50px] ml-2" />
                  <p className="text-gray-300">
                    The current strategy is based on the <span className="text-textactive">Moderate Case Scenario</span>, where we achieve rapid user growth, expansion into mobile, and success in multiplayer monetization. This increases the likelihood of a potential acquisition or IP licensing deal by Q2 2027.
                  </p>
                </>
              ) : null}
              {configuration.scenario === 'worst_case' ? (
                <>
                  <img src={WorstCaseScenario} alt="Worst Case Scenario Icon" className="inline-block w-[50px] h-[50px] ml-2" />
                  <p className="text-gray-300">
                    The current strategy is based on the <span className="text-textactive">Worst Case Scenario</span>, where we achieve rapid user growth, expansion into mobile, and success in multiplayer monetization. This increases the likelihood of a potential acquisition or IP licensing deal by Q2 2027.
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className="space-y-4 pt-5 px-5">
          <h3 className="text-xl font-semibold">Choose an Exit Scenario</h3>
          <p className="text-gray-300">Adjust the strategy to explore different scenarios:</p>
          <div className="flex space-x-4 items-center pb-3">
            <button
              onClick={() => { updateScenario('best_case') }}
              className={clsx(
                "bg-gradient-to-b text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
                { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": configuration.scenario === 'best_case' },
                { "from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": configuration.scenario !== 'best_case' }
              )}
            >
              Best Case
            </button>
            <button
              onClick={() => { updateScenario('moderate_case') }}
              className={clsx(
                "bg-gradient-to-b text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
                { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": configuration.scenario === 'moderate_case' },
                { "from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": configuration.scenario !== 'moderate_case' }
              )}
            >
              Moderate Case
            </button>
            <button
              onClick={() => { updateScenario('worst_case') }}
              className={clsx(
                "bg-gradient-to-b text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
                { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": configuration.scenario === 'worst_case' },
                { "from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": configuration.scenario !== 'worst_case' }
              )}
            >
              Worst Case
            </button>
          </div>
        </div>

        <div className="space-y-4">
          <div className="px-5 pt-5 pb-2">
            <h3 className="text-xl mb-6 font-semibold">Potential Buyers</h3>
            <div className="flex bg-[#111111] px-5 py-3 mb-3 flex-row gap-3 justify-between items-center rounded-lg border border-[#2F3341] divide-x divide-gray-800">
              <div className="w-[14%] text-center text-gray-400">
                <p className="text-xs">Free Users</p>
                <p className="font-BarlowBold text-white">{freeUsers.toLocaleString()}</p>
              </div>
              <div className="w-[14%] text-center text-gray-400">
                <p className="text-xs">Mobile Users</p>
                <p className="font-BarlowBold text-white">{mobileUsers.toLocaleString()}</p>
              </div>
              <div className="w-[14%] text-center text-gray-400">
                <p className="text-xs">Multiplayer Users</p>
                <p className="font-BarlowBold text-white">{multiplayerUsers.toLocaleString()}</p>
              </div>
              <div className="w-[14%] text-center text-gray-400">
                <p className="text-xs">F. Game Users</p>
                <p className="font-BarlowBold text-white">{fullGameUsers.toLocaleString()}</p>
              </div>
              <div className="w-[14%] text-center text-gray-400">
                <p className="text-xs">Total Users</p>
                <p className="font-BarlowBold text-white">{totalUsers.toLocaleString()}</p>
              </div>
              <div className="w-[14%] text-center text-gray-400">
                <p className="text-xs">ARPU</p>
                <p className="font-BarlowBold text-white">{formatCurrency(arpu)}</p>
              </div>
              <div className="w-[14%] text-center text-gray-400">
                <p className="text-xs">Mobile ARPU</p>
                <p className="font-BarlowBold text-white">{formatCurrency(mobileARPU)}</p>
              </div>
            </div>
          </div>

          <div className="max-w-full py-3 px-5 flex flex-row gap-x-5 overflow-x-scroll">
            {potentialBuyers.map((buyer) => {
              const meetsInterestConditions = checkInterestConditions(buyer);
              return (
                <div
                  key={buyer.name}
                  className="relative -top-[10px] w-[200px] flex-shrink-0 flex flex-col bg-[#252A35]/80 p-4 rounded-lg shadow-md border border-[#2F3341] transition transform hover:border-2 hover:border-blue-500 hover:bg-gradient-to-b hover:from-promoterblue1 hover:to-promoterblue2 hover:shadow-xl"
                >
                  <div className="flex mb-3 w-full justify-center items-center">
                    <img
                      src={buyer.logoUrl}
                      alt={`${buyer.name} logo`}
                      className="w-full object-contain"
                    />
                  </div>
                  <div className="flex flex-col gap-1 mt-2">
                    <p className="text-sm text-center font-semibold text-gray-300">Interest Conditions</p>
                    <ul className="list-none text-center list-inside text-sm text-gray-400">
                      {Object.entries(buyer.interestCondition).map(([condition, value]) => (
                        <li key={condition}>
                          {condition === "userBase" ? `User Base ${value.toLocaleString()}` :
                            condition === "ARPU" ? `ARPU $${value}` :
                              condition === "mobileUserBase" ? `Mobile User Base: ${value.toLocaleString()}` :
                                condition === "mobileARPU" ? `Mobile ARPU: $${value}` : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button
                    onClick={(e) => {
                      if (meetsInterestConditions) {
                        navigate("/exit_strategy");
                      }
                    }}
                    to="/exit_strategy"
                    disabled={!meetsInterestConditions}
                    className={clsx(
                      "bg-gradient-to-b text-center text-white py-1 px-3 mt-4 rounded-xl shadow-md shadow-black/30 text-[14px] font-BarlowBold uppercase",
                      { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": meetsInterestConditions },
                      { "from-[#283045] to-[#1d222f] opacity-40 hover:from-[#2d364e] hover:to-[#202634]": !meetsInterestConditions }
                    )}
                  >
                    {meetsInterestConditions ? 'Interested' : 'Not Interested'}
                  </button>
                </div>
              );
            })}
          </div>

        </div>
      </section>
    </section>
  );
}