import { useCallback, useState } from 'react';
import { useSimulation } from "../contexts/SimulationContext";
import ExitStrategyHeader from "./ExitStrategy/ExitStrategyHeader";
import clsx from 'clsx';

import WWELogo from "../images/buyers/wwe.png";
import AEWLogo from "../images/buyers/aew.png";
import PlaionLogo from "../images/buyers/plaion.png";
import FiveZeroFiveGamesLogo from "../images/buyers/505games.png";
import FocusEntertainmentLogo from "../images/buyers/focus.png";
import THQNordicLogo from "../images/buyers/thqnordic.png";
import DevolverDigitalLogo from "../images/buyers/devolver.png";
import TinyBuildLogo from "../images/buyers/tinybuild.png";
import ZyngaLogo from "../images/buyers/zynga.png";
import ScopelyLogo from "../images/buyers/scopely.png";
import InfoIcon from "../layout/Icons/InfoIcon";
import ViewOfferModal from './ExitStrategy/ViewOfferModal';

const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(value);

export const potentialBuyers = [
  {
    "name": "WWE",
    "description": "WWE has a massive fanbase that spans generations. They have been historically cautious about mentioning competitors but seem more open to collaboratios now. A lot of their popular wrestlers are big gaming fans.",
    "interestCondition": {
      "userBase": 1000000,
      "ARPU": 25
    },
    "exitValuationScenario": "best_case",
    "logoUrl": WWELogo,
    "challenges": "WWE would look for a significant fan base to justify the investment. If something becomes wildly popular organically - they are quick to capitalize"
  },
  {
    "name": "AEW",
    "description": "As a newer but rapidly growing wrestling brand, AEW has a reputation for backing riskier ventures. AEW's fanbase tends to be younger, hardcore and more knowledgable about the wider wrestling world.",
    "interestCondition": {
      "userBase": 500000,
      "ARPU": 20
    },
    "exitValuationScenario": "moderate_case",
    "logoUrl": AEWLogo,
    "challenges": "AEW lost a lot of money on their version of WWE2K (Fight Forever) but recently signed a new TV deal which should make them profitable and more willing to invest"
  },
  {
    "name": "Plaion",
    "description": "Plaion owns several mid-tier studios and has experience in sports and simulation games. Their portfolio would benefit from a wrestling game, especially if it performs well within a niche audience.",
    "interestCondition": {
      "userBase": 300000,
      "ARPU": 10
    },
    "exitValuationScenario": "moderate_case",
    "logoUrl": PlaionLogo,
    "challenges": "Although Plaion is open to sports titles, they would want to see strong community engagement and reliable long-term revenue."
  },
  {
    "name": "505 Games",
    "description": "Known for publishing unique and engaging mid-core games, 505 Games may consider a wrestling game with unique gameplay elements. They have a strong focus on user engagement and niche communities.",
    "interestCondition": {
      "userBase": 250000,
      "ARPU": 15
    },
    "exitValuationScenario": "moderate_case",
    "logoUrl": FiveZeroFiveGamesLogo,
    "challenges": "They'd be looking for a solid player base that guarantees steady returns, as well as potential for long-tail sales."
  },
  {
    "name": "Focus Entertainment",
    "description": "Focus Entertainment has a history of investing in niche games with dedicated fan followings. They are more likely to acquire if the game has high word-of-mouth appeal and long-term potential.",
    "interestCondition": {
      "userBase": 300000,
      "ARPU": 12
    },
    "exitValuationScenario": "moderate_case",
    "logoUrl": FocusEntertainmentLogo,
    "challenges": "Focus would be cautious and may want proof that the game has strong community-driven content or expansion potential"
  },
  {
    "name": "THQ Nordic (Embracer Group)",
    "description": "THQ Nordic, part of Embracer Group, has a history with niche titles and nostalgic IPs. They might see value in a wrestling game that taps into a loyal fanbase and offers room for sequels or add-ons.",
    "interestCondition": {
      "userBase": 200000,
      "ARPU": 8
    },
    "exitValuationScenario": "worst_case",
    "logoUrl": THQNordicLogo,
    "challenges": "Embracer Group companies often seek titles that can generate consistent but modest revenue, making them a fallback acquirer if user numbers or revenue don't meet best-case expectations."
  },
  {
    "name": "Devolver Digital",
    "description": "Known for quirky and unique games, Devolver might find value in a wrestling game with a distinctive style or offbeat approach. They prioritize games that attract cult followings.",
    "interestCondition": {
      "userBase": 150000,
      "ARPU": 6
    },
    "exitValuationScenario": "worst_case",
    "logoUrl": DevolverDigitalLogo,
    "challenges": "Devolver would only be interested if the game has a highly unique angle or can attract a devoted niche following"
  },
  {
    "name": "TinyBuild",
    "description": "TinyBuild has shown interest in games with niche appeal and a high level of community engagement. They're known for taking risks on unique titles and would find value in a dedicated wrestling game audience.",
    "interestCondition": {
      "userBase": 200000,
      "ARPU": 7
    },
    "exitValuationScenario": "worst_case",
    "logoUrl": TinyBuildLogo,
    "challenges": "TinyBuild would need confidence in the game's longevity and potential for fan-driven marketing"
  },
  {
    "name": "Zynga",
    "description": "As a mobile gaming giant, Zynga could find value in a wrestling game if it demonstrates high ARPU in mobile conversions and a growing mobile user base.",
    "interestCondition": {
      "mobileUserBase": 300000,
      "mobileARPU": 10
    },
    "exitValuationScenario": "moderate_case",
    "logoUrl": ZyngaLogo,
    "challenges": "Zynga would want to see clear profitability from the mobile side and potential for rapid scaling"
  },
  {
    "name": "Scopely",
    "description": "Known for mobile and social games, Scopely would likely consider acquiring a wrestling game with a highly monetizable mobile base and potential for in-app purchases or social features.",
    "interestCondition": {
      "mobileUserBase": 400000,
      "mobileARPU": 14
    },
    "exitValuationScenario": "moderate_case",
    "logoUrl": ScopelyLogo,
    "challenges": "Scopely would look for high monetization potential and a sizable mobile user base"
  }
];


export default function ExitStrategy() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const [acceptedExitValuation, setAcceptedExitValuation] = useState(0);
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exitAppliedClasses, setExitAppliedClasses] = useState('');

  const freeUsers = result?.current_users.free || 0;
  const mobileUsers = result?.current_users.mobile || 0;
  const multiplayerUsers = result?.current_users.multiplayer || 0;
  const fullGameUsers = result?.current_users.fullGame || 0;
  const totalUsers = mobileUsers + multiplayerUsers + fullGameUsers;
  const arpu = totalUsers > 0 ? result?.total_revenue / totalUsers : 0;
  const mobileRevenue = result?.revenue_over_time.reduce(
    (sum, quarter) => {
      return sum + (quarter.mobileGameSales * configuration?.quarters[quarter.quarterId].income.mobile_app.pricing)
    },
    0
  );
  const mobileARPU = mobileUsers > 0 ? mobileRevenue / mobileUsers : 0;

  const handleViewOffer = (buyer) => {
    setSelectedBuyer(buyer);
    setIsModalOpen(true);
  };

  const calculateExitValuation = (buyer, simulationResult, scenario) => {
    const revenueMultiples = {
      best_case: 8,
      moderate_case: 5,
      worst_case: 3,
    };

    const { revenue_over_time } = simulationResult;
    const lastFourQuarters = revenue_over_time.slice(-4);
    console.log('last4', lastFourQuarters)
    const average12MonthRevenue = lastFourQuarters.reduce((sum, quarter) => sum + quarter.revenue, 0) / lastFourQuarters.length;

    const multiple = revenueMultiples[scenario] || revenueMultiples.moderate_case;
    const exitValuation = average12MonthRevenue * multiple;
    return {
      exitValuation,
      multiple,
      average12MonthRevenue,
      scenario
    };
  };

  const handleAcceptOffer = () => {
    const { exitValuation } = calculateExitValuation(selectedBuyer, result, configuration.scenario);
    setAcceptedExitValuation(exitValuation);
    setExitAppliedClasses("animate-pulse");
    const mainContainer = document.getElementById("main-container");
    if (mainContainer) {
      mainContainer.scrollTo(0, 0)
    } else {
      window.scrollTo(0, 0);
    }
    setIsModalOpen(false);
  };

  const checkInterestConditions = useCallback((buyer) => {
    if (result === null) {
      return false;
    }

    const { userBase = null, ARPU = null, mobileUserBase = null, mobileARPU = null } = buyer.interestCondition;
    const { current_users, total_revenue } = result;

    const totalUsers = current_users.mobile + current_users.multiplayer + current_users.fullGame;
    const overallARPU = totalUsers > 0 ? total_revenue / totalUsers : 0;

    const mobileRevenue = result.revenue_over_time.reduce((sum, quarter) => {
      return sum + (quarter.mobileGameSales * configuration?.quarters[quarter.quarterId].income.mobile_app.pricing)
    }, 0);
    const actualMobileARPU = current_users.mobile > 0 ? mobileRevenue / current_users.mobile : 0;

    const isInterested =
      (userBase === null || current_users.free >= userBase) &&
      (ARPU === null || overallARPU >= ARPU) &&
      (mobileUserBase === null || current_users.mobile >= mobileUserBase) &&
      (mobileARPU === null || actualMobileARPU >= mobileARPU);

    // console.log(`${buyer.name}: ${isInterested}
    //   - Free Users Expected: ${userBase ?? "N/A"}, Acquired: ${current_users.free}
    //   - ARPU Expected: ${ARPU ?? "N/A"}, Achieved: ${overallARPU.toFixed(2)}
    //   - Mobile Users Expected: ${mobileUserBase ?? "N/A"}, Acquired: ${current_users.mobile}
    //   - Mobile ARPU Expected: ${mobileARPU ?? "N/A"}, Achieved: ${actualMobileARPU.toFixed(2)}
    // `);

    return isInterested;
  }, [configuration, result]);


  return (
    <div className="space-y-8 text-white">

      <ExitStrategyHeader />

      <div className="flex flex-row items-start gap-3 rounded-lg w-full justify-start bg-[#252A35]/50 border border-[#2F3341] text-white px-4 py-3" role="alert">
        <InfoIcon className="w-6 h-6 fill-white relative top-[4px]" />
        <div className="flex flex-col gap-5">
          <p>You can set the initial investment details on the left side of the panel. Below that we have a list of potential buyers and their realistic expectations for users and revenue before they would consider buying us. As the simulation progresses, the interest conditions for the buyers may be triggered which will result in an offer.</p>
          <p>The offer received will be based on a multiple of our current revenue in the quarter and dependent on the "scenario" you have selected above. Accepting an offer will calculate our exit valuation and your ROI.</p>
        </div>
      </div>

      <div className="flex flex-row gap-5">

        <section className="w-1/2 flex flex-col p-4 bg-[#111722]/50 rounded-lg shadow-lg border border-[#2F3341]">
          <h2 className="text-[20px] font-BarlowBold mb-4">Your Investment</h2>
          <div className="space-y-4 mb-2 text-gray-400">
            <div className="flex justify-between items-center">
              <p>Enter the amount you expect us to raise in total</p>
              <input
                type="number"
                min="0"
                step="5000"
                value={configuration.investmentDetails.totalRaised}
                placeholder="$"
                onChange={(e) => {
                  setConfiguration((prevConfig) => ({
                    ...prevConfig,
                    investmentDetails: {
                      ...prevConfig.investmentDetails,
                      totalRaised: parseInt(e.target.value)
                    }
                  }));
                }}
                className="w-48 p-2 bg-gray-800 text-white rounded-md focus:outline-none"
              />
            </div>
            <div className="flex justify-between items-center">
              <p>What will be your contribution to this total</p>
              <input
                type="number"
                min="0"
                step="5000"
                value={configuration.investmentDetails.investorContribution}
                placeholder="$"
                className="w-48 p-2 bg-gray-800 text-white rounded-md focus:outline-none"
                onChange={(e) => {
                  setConfiguration((prevConfig) => ({
                    ...prevConfig,
                    investmentDetails: {
                      ...prevConfig.investmentDetails,
                      investorContribution: parseInt(e.target.value)
                    }
                  }));
                }}
              />
            </div>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <p>What percentage ownership (%) will you have in the company</p>
                <input
                  type="number"
                  min="0"
                  step="1"
                  max="100"
                  value={configuration.investmentDetails.investorPercentageOwned}
                  className="w-24 p-2 bg-gray-800 text-white rounded-md focus:outline-none"
                  onChange={(e) => {
                    setConfiguration((prevConfig) => ({
                      ...prevConfig,
                      investmentDetails: {
                        ...prevConfig.investmentDetails,
                        investorPercentageOwned: parseInt(e.target.value)
                      }
                    }));
                  }}
                />
              </div>

              <div className={`flex px-3 py-2 justify-between  bg-promoterblue2 rounded-lg ${exitAppliedClasses}`}>
                <p className="text-white">Projected value of your investment at Exit</p>
                <p className="font-BarlowBold text-textactive">
                  {acceptedExitValuation !== 0 ? formatCurrency((configuration?.investmentDetails.investorPercentageOwned / 100) * acceptedExitValuation) : 'Unavailable until Exit Valuation is set'}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-1/2 flex flex-col p-4 bg-[#111722]/50 rounded-lg shadow-lg border border-[#2F3341]">
          <h2 className="text-[20px] font-BarlowBold mb-4">Projected ROI and Timeline</h2>
          <div className="space-y-4 mb-2 text-gray-400">
            <div className="flex px-3 py-2 justify-between bg-gray-800/50 rounded-lg">
              <p>Initial Investment</p>
              <p className="font-BarlowBold text-white">{formatCurrency(configuration?.investmentDetails.totalRaised)}</p>
            </div>
            <div className="flex px-3 py-2 justify-between bg-gray-800/50 rounded-lg">
              <p>Projected Exit Valuation</p>
              <p className="font-BarlowBold text-white">
                {acceptedExitValuation !== 0 ? formatCurrency(acceptedExitValuation) : 'Accept an offer to set the Exit Valuation'}
              </p>
            </div>
            <div className="flex px-3 py-2 justify-between bg-gray-800/50 rounded-lg">
              <p>Estimated Timeline</p>
              <p className="font-BarlowBold text-white">
                {acceptedExitValuation !== 0 ? `${(configuration.current_quarter / 4).toFixed(2)} years` : 'Unavailable until Exit Valuation is set'}
              </p>
            </div>
            <div className={`flex flex-row delay-75 px-3 py-2 justify-between bg-promoterblue2 rounded-lg ${exitAppliedClasses}`}>
              <p className="text-white">Expected ROI</p>
              <p className="font-BarlowBold text-textactive">
                {acceptedExitValuation !== 0 ?
                  (configuration?.investmentDetails.investorContribution > 0)
                    ? ((((configuration?.investmentDetails.investorPercentageOwned / 100) * acceptedExitValuation / configuration?.investmentDetails.investorContribution) - 1)).toFixed(0) + 'x'
                    : 0
                  : 'Unavailable until Exit Valuation is set'
                }
              </p>
            </div>
          </div>
        </section>

      </div>

      <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
        <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
          <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Potential Buyers</h1>
        </section>
        <section className="flex flex-col gap-5 p-5 h-full bg-[#080C0D] rounded-b-xl text-white">
          <div className="flex bg-[#111111] px-5 py-3 flex-row gap-3 justify-between items-center rounded-lg border border-[#2F3341] divide-x divide-gray-800">
            <div className="w-[14%] text-center text-gray-400">
              <p className="font-BarlowBold text-white">Free Users</p>
              <p>{freeUsers.toLocaleString()}</p>
            </div>
            <div className="w-[14%] text-center text-gray-400">
              <p className="font-BarlowBold text-white">Mobile Users</p>
              <p>{mobileUsers.toLocaleString()}</p>
            </div>
            <div className="w-[14%] text-center text-gray-400">
              <p className="font-BarlowBold text-white">Multiplayer Users</p>
              <p>{multiplayerUsers.toLocaleString()}</p>
            </div>
            <div className="w-[14%] text-center text-gray-400">
              <p className="font-BarlowBold text-white">Full Game Users</p>
              <p>{fullGameUsers.toLocaleString()}</p>
            </div>
            <div className="w-[14%] text-center text-gray-400">
              <p className="font-BarlowBold text-white">Total Users</p>
              <p>{totalUsers.toLocaleString()}</p>
            </div>
            <div className="w-[14%] text-center text-gray-400">
              <p className="font-BarlowBold text-white">ARPU</p>
              <p>{formatCurrency(arpu)}</p>
            </div>
            <div className="w-[14%] text-center text-gray-400">
              <p className="font-BarlowBold text-white">Mobile ARPU</p>
              <p>{formatCurrency(mobileARPU)}</p>
            </div>
          </div>
          <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 gap-4">
            {potentialBuyers.map((buyer) => {
              const meetsInterestConditions = checkInterestConditions(buyer);
              return (
                <li
                  key={buyer.name}
                  className="flex flex-col bg-[#252A35]/80 p-4 rounded-lg shadow-md border border-[#2F3341] transition transform hover:border-2 hover:border-blue-500 hover:bg-gradient-to-b hover:from-promoterblue1 hover:to-promoterblue2 hover:scale-105 hover:shadow-xl"
                >
                  <div className="flex mb-3 w-full justify-center items-center">
                    <img
                      src={buyer.logoUrl}
                      alt={`${buyer.name} logo`}
                      className="w-full object-contain px-10"
                    />
                  </div>
                  <h3 className="font-bold text-lg text-white">{buyer.name}</h3>
                  <p className="text-gray-300 mb-3">{buyer.description}</p>
                  <div className="mt-2">
                    <p className="text-sm font-semibold text-gray-300">Interest Conditions</p>
                    <ul className="list-disc list-inside text-sm text-gray-400">
                      {Object.entries(buyer.interestCondition).map(([condition, value]) => (
                        <li key={condition}>
                          {condition === "userBase" ? `User Base ${value.toLocaleString()}` :
                            condition === "ARPU" ? `ARPU $${value}` :
                              condition === "mobileUserBase" ? `Mobile User Base: ${value.toLocaleString()}` :
                                condition === "mobileARPU" ? `Mobile ARPU: $${value}` : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <p className="text-gray-400 mt-4 mb-4 italic">{buyer.challenges}</p>
                  <button
                    onClick={() => handleViewOffer(buyer)}
                    disabled={!meetsInterestConditions}
                    className={clsx(
                      "bg-gradient-to-b text-white py-2 px-4 mt-auto rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
                      { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": meetsInterestConditions },
                      { "from-[#283045] to-[#1d222f] opacity-40 hover:from-[#2d364e] hover:to-[#202634]": !meetsInterestConditions }
                    )}
                  >
                    {meetsInterestConditions ? 'View Offer' : 'Interest Conditions Not Yet Triggered'}
                  </button>
                </li>
              )
            })}
          </ul>
        </section>
      </section>

      {isModalOpen && (
        <ViewOfferModal
          buyer={selectedBuyer}
          onClose={() => setIsModalOpen(false)}
          onAccept={handleAcceptOffer}
          calculateExitValuation={calculateExitValuation}
        />
      )}
    </div >

  );
}