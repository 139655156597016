import MarketingImage1 from '../../../images/marketing-1.png';

export default function MarketingSlideTotalMarketSize() {
  return (
    <div className="flex w-1/5 h-[200px] items-center bg-gradient-to-t from-[#252A35]/40 to-transparent space-x-4">
      <div
        className="flex p-5 justify-center items-end flex-col w-full h-full bg-no-repeat bg-contain bg-[center_left]"
        style={{
          backgroundImage: `url(${MarketingImage1})`,
        }}
      >
        <div className="flex text-right flex-col items-end">

          <p className="text-gray-400 text-[37px] uppercase font-BarlowBold animate__animated animate__fadeInUp animate__delay-2s">
            500M+
          </p>

          <h2 className="text-white font-BarlowBlack text-[16px] uppercase animate__animated animate__fadeIn animate__delay-1s">
            Global Worldwide<br /> Wrestling Fans
          </h2>


          <div className="mt-4">
            <p className="text-textactive font-BarlowBlack text-[16px] animate__animated animate__zoomIn animate__delay-3s">
              25M of them<br /> bought WWE2K!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}