import { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { items } from '../TimelinePanel';
import NextIcon from '../../../layout/Icons/NextIcon';

function useOutsideClickHandler(ref, setShowDropdownItems) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id !== 'toggleJumpToDropdown'
      ) {
        setShowDropdownItems(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShowDropdownItems]);
}

function JumpToDropdown({ setSelectedTimeLineEntry, setSelectedTimeLineEntryIndex }) {

  const [showDropdownItems, setShowDropdownItems] = useState(false);
  const dropdownWrapperRef = useRef(null);

  useOutsideClickHandler(
    dropdownWrapperRef,
    setShowDropdownItems,
  );

  const toggleJumpToDropdown = () => {
    setShowDropdownItems((prev) => !prev);
  };

  return (
    <div className="relative flex">
      <button
        id="toggleJumpToDropdown"
        onClick={toggleJumpToDropdown}
        type="button"
        className={clsx(
          "w-[280px] text-[15px] flex flex-row justify-between items-center gap-3 text-white py-1.5 px-4 font-BarlowBold uppercase shadow-lg shadow-black/30",
          { "rounded-t-xl bg-[#1e2330]": showDropdownItems },
          { "rounded-xl bg-gradient-to-b hover:from-[#2a3148] hover:to-[#1d222e] from-[#212739] to-[#1e2330]": !showDropdownItems },
        )}
      >
        Key Events
        <NextIcon className="w-[14px] h-[14px] fill-gray-500 rotate-90" />
      </button>
      {showDropdownItems && (
        <div
          className="z-20 absolute top-[38px] w-[280px] overflow-hidden shadow-lg shadow-black/40 bg-gradient-to-b from-[#171b25] to-[#212739] rounded-b-xl border border-transparent border-t-gray-700"
          ref={dropdownWrapperRef}
        >
          <ul className="text-[14px] divide-y divide-gray-700">
            {items.map((dropdownItem, index) => (
              <li
                key={dropdownItem.key}
                className="group flex items-center py-2.5 px-2 relative text-white hover:bg-gray-800 cursor-pointer"
                onClick={() => {
                  setSelectedTimeLineEntry(dropdownItem)
                  setSelectedTimeLineEntryIndex(index);
                  setShowDropdownItems(false);
                }}
              >
                <div className="group-hover:bg-blue-500 bg-transparent absolute left-0 top-0 bottom-0 w-1" />
                <span className="pl-4">{dropdownItem.title}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default JumpToDropdown;