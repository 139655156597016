export default function BackIcon({ className = "" }) {

  return (
    <svg height="512" className={className} viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g id="_07-Rewind" data-name="07-Rewind">
        <path d="m27.53 4a2.47 2.47 0 0 0 -1.53.57l-8 6.63v-4.73a2.47 2.47 0 0 0 -4-1.9l-10.82 9a3.13 3.13 0 0 0 0 4.82l10.82 9a2.47 2.47 0 0 0 4-1.86v-4.73l8 6.63a2.47 2.47 0 0 0 4-1.9v-19.06a2.48 2.48 0 0 0 -2.47-2.47z" />
      </g>
    </svg>
  )

}
