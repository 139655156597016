import { useEffect, useState } from "react";
import { useSimulation } from "../contexts/SimulationContext";
import FunnelPanel from "./UserAcquisition/FunnelPanel";
import UserAcquisitionHeader from "./UserAcquisition/UserAcquisitionHeader";
import UserGrowthChartPanel from "./UserAcquisition/UserGrowthChartPanel";
import TargetIcon from "../layout/Icons/TargetIcon";
import clsx from "clsx";
import { debounce } from "lodash";
import FunnelConfigurationPanel from "./UserAcquisition/FunnelConfigurationPanel";

export default function UserAcquisition() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();

  const [currentQuarterDetails, setCurrentQuarterDetails] = useState(null)
  const [localConfiguration, setLocalConfiguration] = useState(null);
  const [isLocalExpensesDirty, setIsLocalExpensesDirty] = useState(false);
  const [availableMarketingBudget, setAvailableMarketingBudget] = useState(0);

  useEffect(() => {
    if (result) {
      const localConf = JSON.parse(JSON.stringify(configuration.quarters[String(configuration.current_quarter)]));
      const currentQuarterRes = result.revenue_over_time.find(entry => entry.quarterId === configuration.current_quarter);
      setCurrentQuarterDetails(currentQuarterRes);
      setLocalConfiguration({ ...localConf });

      const totalMarketingBudget = localConf.expenses.marketing.budget;
      const budgetUsedForMarketingResources = localConf.expenses.marketing.resources.reduce((sum, resource) => sum += resource.salary, 0);
      setAvailableMarketingBudget(totalMarketingBudget - budgetUsedForMarketingResources);
    }
  }, [configuration, result])

  const removeMarketingChannel = (channelId) => {
    setLocalConfiguration(prev => {
      return {
        ...prev,
        expenses: {
          ...prev.expenses,
          marketing: {
            ...prev.expenses.marketing,
            channels: prev.expenses.marketing.channels.filter(channel => channel.id !== channelId)
          }
        }
      }
    });
    setIsLocalExpensesDirty(true);
  }

  const debouncedUpdateBudgetAllocation = debounce((channelId, newAllocation) => {
    updateBudgetAllocation(channelId, newAllocation);
  }, 5);

  const updateChannelConfiguration = (channelId, configKey, newValue) => {
    setLocalConfiguration((prev) => {
      const currChannel = prev.expenses.marketing.channels.find((channel) => channel.id === channelId);
      const otherChannels = prev.expenses.marketing.channels.filter((channel) => channel.id !== channelId);
      currChannel[configKey] = newValue;
      return {
        ...prev,
        expenses: {
          ...prev.expenses,
          marketing: {
            ...prev.expenses.marketing,
            channels: prev.expenses.marketing.channels.map(channel => {
              if (channel.id !== channelId) {
                return { ...channel };
              }
              return {
                ...channel,
                [configKey]: newValue
              };
            })
          },
        },
      };
    });
    setIsLocalExpensesDirty(true);
  };

  const updateBudgetAllocation = (channelId, newAllocation) => {
    setLocalConfiguration((prev) => {
      const totalAllocated = prev.expenses.marketing.channels.reduce(
        (acc, channel) => acc + parseFloat(channel.percentageBudgetAllocated),
        0
      );

      const currChannel = prev.expenses.marketing.channels.find((channel) => channel.id === channelId);
      const currentAllocation = parseFloat(currChannel.percentageBudgetAllocated);
      const excess = totalAllocated - currentAllocation + newAllocation - 100;

      if (excess > 0) {
        // Calculate channels to adjust (excluding the one being updated)
        const channelsToAdjust = prev.expenses.marketing.channels.filter((channel) => channel.id !== channelId);
        const equalReduction = excess / channelsToAdjust.length;

        // Adjust each other channel equally
        const adjustedChannels = channelsToAdjust.map((channel) => ({
          ...channel,
          percentageBudgetAllocated: Math.max(channel.percentageBudgetAllocated - equalReduction, 0),
        }));

        // Update the current channel with the new allocation
        const updatedChannel = {
          ...currChannel,
          percentageBudgetAllocated: Math.min(newAllocation, 100),
        };

        return {
          ...prev,
          expenses: {
            ...prev.expenses,
            marketing: {
              ...prev.expenses.marketing,
              channels: [updatedChannel, ...adjustedChannels],
            },
          },
        };
      } else {
        // If within budget, update the selected channel directly
        return {
          ...prev,
          expenses: {
            ...prev.expenses,
            marketing: {
              ...prev.expenses.marketing,
              channels: prev.expenses.marketing.channels.map((channel) =>
                channel.id === channelId
                  ? { ...channel, percentageBudgetAllocated: newAllocation }
                  : channel
              ),
            },
          },
        };
      }
    });
    setIsLocalExpensesDirty(true);
  };

  const applyChanges = () => {
    setConfiguration((prevConfig) => {
      const updatedConfig = { ...prevConfig };
      updatedConfig.quarters[String(prevConfig.current_quarter)] = localConfiguration;
      return updatedConfig;
    });
    setIsLocalExpensesDirty(false);
  }

  return (
    <div className="text-white flex flex-col gap-5">
      <UserAcquisitionHeader
        currentQuarterDetails={currentQuarterDetails}
        localConfiguration={localConfiguration}
        setLocalConfiguration={setLocalConfiguration}
        isLocalExpensesDirty={isLocalExpensesDirty}
        setIsLocalExpensesDirty={setIsLocalExpensesDirty}
      />
      {/* <pre>{JSON.stringify(localConfiguration?.expenses.marketing, null, 2)}</pre> */}
      <div className="flex flex-col justify-center items-center w-full min-h-[250px] bg-[#ffffff]/5 rounded-xl p-4">
        {localConfiguration?.expenses.marketing.channels.length > 0 ? (
          <div className="h-full grid grid-cols-4 gap-4 place-items-center justify-items-center">
            {localConfiguration?.expenses.marketing.channels.map((channel, index) => (
              <div key={channel.id} className="flex flex-col justify-beween text-[15px] text-gray-400 w-full p-5 h-full bg-[#252A35]/50 rounded-lg border border-[#2F3341] shadow-lg">

                <div className="flex flex-row items-start gap-3">
                  <TargetIcon className="relative top-[3px] min-w-6 max-w-6 min-h-6 max-w-6 fill-white" />
                  <div className="flex text-white flex-row gap-3 line-clamp-2 ellipsis items-start text-[18px] font-bold mb-3 uppercase">
                    <h2 title={channel.channelName} className="line-clamp-2">{channel.channelName}</h2>
                  </div>
                </div>
                <p className="mb-3">{channel.description}</p>
                <div className="w-full flex flex-col">
                  <div className="my-3 flex items-center justify-between">
                    <span className="pr-5 w-[150px] font-BarlowBold">Budget Allocated</span>
                    <input
                      id="range"
                      type="range"
                      step="5"
                      min="0"
                      max="100"
                      value={channel.percentageBudgetAllocated}
                      onChange={(e) => { debouncedUpdateBudgetAllocation(channel.id, parseInt(e.target.value)) }}
                      className="block w-full px-3 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md"
                    />
                    <span className="pl-5 w-[100px] text-right text-gray-300">{channel.percentageBudgetAllocated}%</span>
                  </div>
                  <div className="my-3 mb-5 flex items-center justify-between">
                    <span className="pr-5 w-[150px] font-BarlowBold">Frequency</span>
                    <input
                      step="1"
                      type="number"
                      className="w-full px-2 py-1 border bg-inputbg border-inputborder rounded-lg shadow-forminput"
                      value={channel.frequency}
                      onChange={(e) => { updateChannelConfiguration(channel.id, 'frequency', e.target.value) }}
                    />
                  </div>
                  <div className="w-full flex flex-row justify-between mb-3">
                    <div className="w-1/2">
                      <p className="font-BarlowBold mb-2">Total Reach</p>
                      <p className="text-gray-300">
                        {Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 0,
                        }).format(channel.totalReach)}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="font-BarlowBold mb-2">Available Reach</p>
                      <p className="text-gray-300">
                        {Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 0,
                        }).format((channel.percentageBudgetAllocated / 100) * channel.totalReach)}
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex flex-row justify-between mb-3">
                    <div className="w-1/2">
                      <p className="font-BarlowBold mb-2">Impressions</p>
                      <p className="text-gray-300">
                        {Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 0,
                        }).format((channel.percentageBudgetAllocated / 100) * channel.totalReach * channel.frequency)}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="font-BarlowBold mb-2">Click Through Rate</p>
                      <div className="text-gray-300">
                        <input
                          step="0.01"
                          type="number"
                          className="w-full px-2 py-1 border bg-inputbg border-inputborder rounded-lg shadow-forminput"
                          value={channel.clickThroughRate}
                          onChange={(e) => { updateChannelConfiguration(channel.id, 'clickThroughRate', e.target.value) }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-row justify-between mb-3">
                    <div className="w-1/2">
                      <p className="font-BarlowBold mb-2">Visits</p>
                      <p className="text-gray-300">
                        {Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 0,
                        }).format((channel.percentageBudgetAllocated / 100) * channel.totalReach * channel.frequency * channel.clickThroughRate)}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="font-BarlowBold mb-2">Conversion Rate</p>
                      <div className="text-gray-300">
                        <input
                          step="0.01"
                          type="number"
                          className="w-full px-2 py-1 border bg-inputbg border-inputborder rounded-lg shadow-forminput"
                          value={channel.conversionToFreeGameRate}
                          onChange={(e) => { updateChannelConfiguration(channel.id, 'conversionToFreeGameRate', e.target.value) }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-row justify-between mb-3">
                    <div className="w-1/2">
                      <p className="font-BarlowBold mb-2">Conversions</p>
                      <p className="text-gray-300">
                        {Intl.NumberFormat('en-US', {
                          style: 'decimal',
                          minimumFractionDigits: 0,
                        }).format((channel.percentageBudgetAllocated / 100) * channel.totalReach * channel.frequency * channel.clickThroughRate * channel.conversionToFreeGameRate)}
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="font-BarlowBold mb-2">Average Cost Per User</p>
                      <p className="text-gray-300">
                        {Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                        }).format(
                          (channel.percentageBudgetAllocated / 100) * availableMarketingBudget / ((channel.percentageBudgetAllocated / 100) * channel.totalReach * channel.frequency * channel.clickThroughRate * channel.conversionToFreeGameRate)
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex flex-row justify-between mt-3">
                    <button
                      onClick={() => { removeMarketingChannel(channel.id) }}
                      className="w-full bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-1.5 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
                    >
                      Remove Channel
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        {localConfiguration?.expenses.marketing.channels.length === 0 && (
          <p className="w-full text-gray-400 text-center">No Marketing Channels active in this quarter, you can add one by clicking on the drop down button on the top right of this screen...</p>
        )}
      </div>
      <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
        <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
          <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">The Funnel</h1>
          <button
            onClick={() => { applyChanges() }}
            className={clsx(
              "bg-gradient-to-b text-white py-1 px-3 rounded-lg shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
              { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": isLocalExpensesDirty },
              { "opacity-75 from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": !isLocalExpensesDirty }
            )}
            disabled={!isLocalExpensesDirty}
          >
            Apply Changes
          </button>
        </section>
        <section className="flex p-5 gap-5 flex-row justify-between h-full bg-[#080C0D] rounded-b-xl text-white">
          <FunnelPanel
            localConfiguration={localConfiguration}
          />
          <FunnelConfigurationPanel
            localConfiguration={localConfiguration}
            setLocalConfiguration={setLocalConfiguration}
            setIsLocalExpensesDirty={setIsLocalExpensesDirty}
          />
        </section>
      </section>
      <UserGrowthChartPanel />
    </div>
  );
}