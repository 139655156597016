export default function TargetIcon({ className = "" }) {

  return (
    <svg id="fi_3679862" className={className} enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m396.734 183.609c13.749 24.844 21.591 53.393 21.591 83.741 0 95.674-77.836 173.51-173.51 173.51s-173.509-77.836-173.509-173.509c0-95.672 77.835-173.509 173.509-173.509 29.586 0 57.462 7.451 81.861 20.563l35.73-35.73c-34.934-21.865-75.361-33.575-117.592-33.575-59.365 0-115.178 23.118-157.156 65.096s-65.096 97.79-65.096 157.156c0 59.365 23.118 115.178 65.096 157.155 41.979 41.979 97.791 65.097 157.156 65.097s115.178-23.118 157.155-65.097c41.979-41.978 65.097-97.79 65.097-157.155 0-42.951-12.108-84.038-34.699-119.376z"></path>
        <g>
          <g>
            <path d="m261.823 226.279c-15.999-6.556-35.142-3.406-48.118 9.571-17.312 17.312-17.312 45.238 0 62.416 17.305 17.305 45.231 17.305 62.407 0 12.984-12.986 16.127-32.127 9.571-48.118l126.788-126.788 21.115 2.752 55.851-55.858-42.215-5.633-5.641-42.222-55.858 55.986 2.759 21.108zm0 0"></path>
          </g>
        </g>
        <path d="m327.49 252.852c.824 4.711 1.257 9.555 1.257 14.499 0 46.281-37.651 83.933-83.933 83.933-46.28 0-83.933-37.651-83.933-83.933 0-46.28 37.652-83.932 83.933-83.932 4.07 0 8.071.297 11.988.859l39.289-39.289c-15.783-6.639-33.108-10.313-51.277-10.313-73.157 0-132.675 59.518-132.675 132.675s59.518 132.676 132.675 132.676 132.676-59.519 132.676-132.676c0-18.951-4.003-36.982-11.193-53.306z"></path>
      </g>
    </svg>
  )
}