export default function FastForwardIcon({ className = "" }) {

  return (
    <svg height="512" className={className} viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
      <g id="_06-Fast-forward" data-name="06-Fast-forward">
        <path d="m28.87 13.59-10.82-9a2.47 2.47 0 0 0 -4.05 1.88v4.73l-7.95-6.63a2.47 2.47 0 0 0 -4 1.9v19.06a2.47 2.47 0 0 0 4.05 1.9l8-6.63v4.73a2.47 2.47 0 0 0 4.05 1.9l10.82-9a3.13 3.13 0 0 0 0-4.82z" />
      </g>
    </svg>
  )

}