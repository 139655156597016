import { useSimulation } from "../../contexts/SimulationContext";
import { LineChart, Line, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function UserGrowthChartPanel() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  return (
    <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">User Growth</h1>
      </section>
      <section className="flex p-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">
        <div className="bg-gradient-to-b from-transparent to-[#252A35] rounded-xl h-[500px] p-5 flex items-center justify-center text-gray-500">
          <ResponsiveContainer width="100%" height={500}>
            <AreaChart data={result?.revenue_over_time || []} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
              <defs>
                <linearGradient id="freeUsers" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="mobilePurchases" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="multiplayerSubscriptions" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="fullGamePurchases" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#bf94e4" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#bf94e4" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area type="monotone" dataKey="users.freeUsers" stroke="#8884d8" fillOpacity={1} fill="url(#freeUsers)" name="Free Game Players" />
              <Area type="monotone" dataKey="users.mobileUsers" stroke="#82ca9d" fillOpacity={1} fill="url(#mobilePurchases)" name="Mobile Game Purchases" />
              <Area type="monotone" dataKey="users.multiplayerUsers" stroke="#ffc658" fillOpacity={1} fill="url(#multiplayerSubscriptions)" name="Multiplayer Subscribers" />
              <Area type="monotone" dataKey="users.fullGameUsers" stroke="#bf94e4" fillOpacity={1} fill="url(#fullGamePurchases)" name="Full Game Purchases" />

              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <XAxis dataKey="quarter" />
              <YAxis />
              <Tooltip />
              <Legend />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </section>
    </section>
  );
}