import { useEffect, useState } from "react";
import { useSimulation } from "../../contexts/SimulationContext";
import clsx from 'clsx';
import AddMarketingChannelDropdown from "./AddMarketingChannelDropdown";

const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(value);


export default function UserAcquisitionHeader({ currentQuarterDetails, localConfiguration, setLocalConfiguration, isLocalExpensesDirty, setIsLocalExpensesDirty }) {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const [availableMarketingBudget, setAvailableMarketingBudget] = useState(0);
  const [allocatedMarketingBudget, setAllocatedMarketingBudget] = useState(0);


  useEffect(() => {
    const currQuarterConfig = configuration.quarters[configuration.current_quarter];

    const totalMarketingBudget = currQuarterConfig.expenses.marketing.budget;
    const budgetUsedForMarketingResources = currQuarterConfig.expenses.marketing.resources.reduce((sum, resource) => sum += resource.salary, 0);

    const totalMarketingBudgetAvailableMinusResources = totalMarketingBudget - budgetUsedForMarketingResources
    setAvailableMarketingBudget(totalMarketingBudgetAvailableMinusResources);
    const budgetAllocatedToMarketingChannels = currQuarterConfig.expenses.marketing.channels.reduce((sum, channel) => sum += channel.percentageBudgetAllocated / 100 * totalMarketingBudgetAvailableMinusResources ?? 0, 0);
    setAllocatedMarketingBudget(budgetAllocatedToMarketingChannels);

  }, [configuration]);

  const addMarketingChannel = (marketingChannel) => {
    setLocalConfiguration(prev => {
      return {
        ...prev,
        expenses: {
          ...prev.expenses,
          marketing: {
            ...prev.expenses.marketing,
            channels: [
              ...prev.expenses.marketing.channels,
              marketingChannel
            ]
          }
        }
      }
    });
    setIsLocalExpensesDirty(true);
  }

  const applyChanges = () => {
    setConfiguration((prevConfig) => {
      const updatedConfig = { ...prevConfig };
      updatedConfig.quarters[String(prevConfig.current_quarter)] = localConfiguration;
      return updatedConfig;
    });
    setIsLocalExpensesDirty(false);
  }

  return (
    <header className="flex justify-between items-center">
      <h1 className="text-2xl font-BarlowBold text-white">User Acquisition</h1>

      <div className="flex flex-col items-center">
        <span className="text-sm text-gray-400">Current Quarter</span>
        <span className="text-xl font-BarlowBold text-white">{currentQuarterDetails?.quarter ?? 'Unknown'}</span>
      </div>

      <div className="flex flex-col items-center">
        <span className="text-sm text-gray-400">Marketing Budget Available</span>
        <span className="text-xl font-BarlowBold text-white">{formatCurrency(availableMarketingBudget)}</span>
      </div>

      <div className="flex flex-col items-center">
        <span className="text-sm text-gray-400">Marketing Budget Allocated</span>
        <span className="text-xl font-BarlowBold text-white">{formatCurrency(allocatedMarketingBudget)}</span>
      </div>

      <div className="flex space-x-3">
        <button
          onClick={() => { applyChanges() }}
          className={clsx(
            "bg-gradient-to-b text-white py-1 px-3 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase",
            { "from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4": isLocalExpensesDirty },
            { "opacity-75 from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634]": !isLocalExpensesDirty }
          )}
          disabled={!isLocalExpensesDirty}
        >
          Apply Changes
        </button>
        <AddMarketingChannelDropdown
          addMarketingChannel={addMarketingChannel}
        />
      </div>
    </header>
  );
}