import { useSimulation } from "../../contexts/SimulationContext";
import { LineChart, Line, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function OverviewMetricsPanel() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  return (
    <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Overview Metrics</h1>
      </section>
      <section className="flex p-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">
        <div className="bg-gradient-to-b from-transparent to-[#252A35] rounded-xl h-[500px] p-5 flex items-center justify-center text-gray-500">
          <ResponsiveContainer width="100%" height={500}>
            <AreaChart data={result?.revenue_over_time || []} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
              <defs>
                <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorExpenses" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorProfit" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorUsers" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>

              <Area type="monotone" dataKey="revenue" stroke="#8884d8" fillOpacity={1} fill="url(#colorRevenue)" name="Revenue" />
              <Area type="monotone" dataKey="expenses" stroke="#82ca9d" fillOpacity={1} fill="url(#colorExpenses)" name="Expenses" />
              <Area type="monotone" dataKey="profit" stroke="#ffc658" fillOpacity={1} fill="url(#colorProfit)" name="Profit" />
              <Line type="monotone" dataKey="users" stroke="#00C49F" dot={{ r: 4 }} name="Users" />

              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <XAxis dataKey="quarter" />
              <YAxis />
              <Tooltip />
              <Legend />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </section>
    </section>
  );
}