import { Link } from 'react-router-dom';
import DealIcon from "../../images/deal.svg";
import LaunchIcon from "../../images/launch.svg";
import AnnualReleaseIcon from "../../images/annual_release.svg";
import MobileIcon from "../../images/mobile.svg";
import MultiplayerIcon from "../../images/multiplayer.svg"

export default function BusinessModelPanel() {

  return (
    <section className="relative w-1/2 bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Business Model</h1>
      </section>
      <section className="flex flex-col h-full bg-[#080C0D] rounded-b-xl text-white">

        <div className="relative bg-gradient-to-r from-[#252A35]/40 to-transparent px-6 py-5 shadow-md hover:shadow-lg transition-all">
          <div className="flex flex-row items-center gap-5 py-5">
            <div className="min-w-[100px] max-w-[100px] px-3">
              <img src={LaunchIcon} className="w-full h-full glow-effect drop-shadow-lg brightness-125" alt="Handshake Icon" />
            </div>
            <div>
              <h3 className="text-xl mb-3 font-BarlowBold uppercase">Free-to-Play Launch</h3>
              <p className="text-gray-300">
                <span className="text-textactive">Release the current game for free</span> to attract a huge player and build brand awareness. The free-to-play model will also allow us to stomp out any competition.
              </p>
            </div>
          </div>
        </div>

        <div className="relative bg-gradient-to-b from-[#252A35]/40 to-transparent px-6 py-5 shadow-md hover:shadow-lg transition-all">
          <div className="flex flex-col items-center text-center space-y-4">
            <h3 className="text-xl font-BarlowBold uppercase">Feature Expansion</h3>
            <p className="text-gray-300 pb-3">
              Even if we release the current version of the game for free that does not mean we cannot monetize it further...
            </p>
            <div className="flex flex-row justify-between text-left items-start text-gray-300 space-y-2">
              <div className="flex flex-row gap-5 mb-5">
                <img src={MobileIcon} className="px-3 w-[100px] h-[100px] glow-effect drop-shadow-lg brightness-125" alt="Mobile Version Icon" />
                <p>
                  <span className="font-semibold text-white">Mobile Version:</span> We can quickly adapt our current code to launch a <span className="text-textactive">paid mobile version</span> of the game. Players are eager for the option to play
                  on the go during commutes, bathroom breaks etc.
                </p>
                <img src={MultiplayerIcon} className="px-3 w-[100px] h-[100px] glow-effect drop-shadow-lg brightness-125" alt="Multiplayer Icon" />
                <p>
                  <span className="font-semibold text-white">Multiplayer Mode:</span>
                  With a cloud-hosted game database and a few tweaks to our game client, we can introduce a <span className="text-textactive">monthly subscription</span> that lets players compete against each other.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-gradient-to-r from-[#252A35]/40 to-transparent px-6 py-5 shadow-md hover:shadow-lg transition-all">
          <div className="flex flex-row items-center gap-5 py-5 ">
            <div className="min-w-[100px] max-w-[100px] px-3">
              <img src={AnnualReleaseIcon} className="w-full h-full glow-effect drop-shadow-lg brightness-125" alt="Handshake Icon" />
            </div>
            <div>
              <h3 className="text-xl mb-3 font-BarlowBold uppercase">Yearly Release</h3>
              <p className="text-gray-300">
                We will generate recurring revenue by turning fans' most-wanted features into <span className="text-textactive">annual full-priced premium releases</span>.
              </p>
            </div>
          </div>
        </div>

        <div className="relative bg-gradient-to-l from-[#252A35]/40 to-transparent px-6 py-5 shadow-md hover:shadow-lg transition-all">
          <div className="flex flex-row items-center gap-5 py-5 ">
            <div className="min-w-[100px] max-w-[100px] px-3">
              <img src={DealIcon} className="w-full h-full glow-effect drop-shadow-lg brightness-125" alt="Handshake Icon" />
            </div>
            <div>
              <h3 className="text-xl mb-3 font-BarlowBold uppercase">Potential Exit</h3>
              <p className="text-gray-300">
                Once the game's market presence is strong - we will aim to <span className="text-textactive">license or sell the IP</span> to major players like <span className="font-semibold text-white">AEW</span>, <span className="font-semibold text-white">WWE</span>, or a third-party publisher.
              </p>
            </div>
          </div>
        </div>

        <div className="p-2">
          <Link
            to="/projections"
            className="block text-center w-full bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
          >
            Explore the Business Model
          </Link>
        </div>

      </section>
    </section>
  );
}