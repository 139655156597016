import { useState, useEffect, useCallback } from "react"
import { Outlet } from "react-router-dom";
import Background from "../images/background.png";
import TopBar from "./AppLayout/TopBar";
import SideBar from "./AppLayout/SideBar";
import Footer from "./AppLayout/Footer";
import { SimulationProvider, useSimulation } from "../contexts/SimulationContext";
import ToiletImage from "../images/toilet.png";
import HandleScenarioChange from "./HandleScenarioChange";
import { Helmet } from "react-helmet";
// import { ShepherdJourneyProvider, useShepherd } from 'react-shepherd';
// import Shepherd from 'shepherd.js';
// const tourOptions = {
//   defaultStepOptions: {
//     cancelIcon: {
//       enabled: false
//     }
//   },
//   useModalOverlay: false,
// };

export default function AppLayout() {

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [forceDesktopView, setForceDesktopView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    // const tour = new Shepherd.Tour({
    //   ...tourOptions,
    // });
    // tour.addStep({
    //   id: 'example-step',
    //   text: 'Hi, I will give you a quick tour of this portal so can begin to explore the business model further...',
    //   attachTo: {
    //     element: '#tour-start',
    //     on: 'bottom'
    //   },
    //   classes: 'relative bg-gradient-to-b from-[#252A35] to-[#080C0D] text-white text-[15px] border border-[#2F3341] overflow-hidden p-5 flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50',
    //   buttons: [
    //     {
    //       text: 'Next',
    //       classes: 'bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white my-2 py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase',
    //       action: tour.next
    //     }
    //   ]
    // });
    // tour.addStep({
    //   id: 'quarter-slider-step',
    //   text: 'Hi, I will give you a quick tour of this portal so can begin to explore the business model further...',
    //   attachTo: {
    //     element: '#quarterSlider',
    //     on: 'bottom'
    //   },
    //   classes: 'relative bg-gradient-to-b from-[#252A35] to-[#080C0D] text-white text-[15px] border border-[#2F3341] overflow-hidden p-5 flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50',
    //   buttons: [
    //     {
    //       text: 'Next',
    //       classes: 'bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white my-2 py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase',
    //       action: tour.next
    //     }
    //   ]
    // })
    // tour.start();
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  return (
    <SimulationProvider>
      <div
        id="app-container"
        className="flex flex-col justify-center items-center p-0 bg-cover bg-center bg-no-repeat h-screen w-screen overflow-hidden"
        style={{
          backgroundImage: `url(${Background})`,
        }}
      >
        {isMobile && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
              <div className="flex flex-col items-center bg-gradient-to-t from-gray-900 to-gray-800 text-white text-center p-8 rounded-xl shadow-lg max-w-md mx-4">
                <h2 className="text-2xl font-BarlowBold mb-4">Seriously... you're checking out investment opportunities on the toilet!!?</h2>
                <div className="py-4 w-[200px]">
                  <img src={ToiletImage} alt="Investor sitting on a toilet" className="w-full h-full object-contain" />
                </div>
                <p className="text-gray-500 text-sm mb-6">
                  Please poop in peace. This portal is best viewed on a larger device due to the density of the data displayed
                </p>
                <button
                  id="toggleFilterDropdownButton"
                  type="button"
                  onClick={() => { setIsMobile(false); setForceDesktopView(true); }}
                  className="bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 rounded-xl shadow-md shadow-black/50 text-[14px] font-BarlowBold uppercase"
                >
                  Don't lecture me on my bowel habits...
                </button>
              </div>
            </div>
          </>
        )}

        {forceDesktopView && (
          <Helmet>
            <meta name="viewport" content="width=1920, initial-scale=1" />
          </Helmet>
        )}

        <HandleScenarioChange />
        <div className="flex flex-row w-full h-full font-sans">
          <div className="relative flex flex-col overflow-hidden w-full h-full">
            <TopBar openSideBar={setIsSideBarOpen} />
            <div id="main-container" className="relative w-full h-full p-5 box-border flex-grow overflow-hidden overflow-y-scroll">
              <Outlet />
            </div>
            <Footer />
          </div>
          {isSideBarOpen && (
            <SideBar />
          )}
        </div>
      </div>
    </SimulationProvider>
  )

}