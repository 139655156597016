import { useSimulation } from "../../contexts/SimulationContext";
import { Link } from "react-router-dom";

export default function RevenueMultiplayerSubscriptionsPanel() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const isCompleted = configuration?.quarters[configuration?.current_quarter].isMultiplayerReady;

  const updateMultiplayerSubscriptionPrice = (newPrice) => {
    const currentQuarter = configuration?.quarters[configuration?.current_quarter];
    if (!currentQuarter) {
      return;
    }
    currentQuarter.income.multiplayer_subscriptions.pricing = parseInt(newPrice);
    setConfiguration((prevConfig) => {
      const updatedConfig = { ...prevConfig };
      updatedConfig.quarters[String(prevConfig.current_quarter)] = currentQuarter;
      return updatedConfig;
    });
  }

  const updateMultiplayerSubscriptionChurnRate = (newChurn) => {
    const currentQuarter = configuration?.quarters[configuration?.current_quarter];
    if (!currentQuarter) {
      return;
    }
    currentQuarter.income.multiplayer_subscriptions.churn_rate = parseFloat(newChurn);
    setConfiguration((prevConfig) => {
      const updatedConfig = { ...prevConfig };
      updatedConfig.quarters[String(prevConfig.current_quarter)] = currentQuarter;
      return updatedConfig;
    });
  }

  return (
    <section className="relative w-1/4 bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Revenue Stream: Multiplayer Subscriptions</h1>
      </section>
      <section className="flex p-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">
        {!isCompleted ? (
          <div className="flex flex-col gap-3 w-full">
            <p className="text-red-400">This revenue stream is not completed yet</p>
            <p className="text-gray-400">You can check the expected completion date and modify when the revenue stream is available on the "Development Milestones" page</p>
            <Link
              to="/development_milestones"
              className="w-full text-center bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 mt-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
            >
              Development Milestones
            </Link>
          </div>
        ) : (
          <>
            <label className="text-sm font-semibold">Monthly Subscription Price:</label>
            <p className="text-xs text-gray-400 mb-2">Set the monthly subscription price for multiplayer access.</p>
            <input
              type="number"
              min="0"
              step="1"
              value={configuration?.quarters[configuration?.current_quarter].income.multiplayer_subscriptions.pricing}
              onChange={(e) => updateMultiplayerSubscriptionPrice(e.target.value)}
              className="mt-2 p-2 bg-[#1D2026] rounded text-white"
            />
            <label className="text-sm font-semibold mt-4">Churn Rate (% per quarter):</label>
            <p className="text-xs text-gray-400 mb-2">Percentage of subscribers expected to cancel each quarter.</p>
            <input
              type="number"
              min="0"
              max="100"
              step="1"
              value={configuration?.quarters[configuration?.current_quarter].income.multiplayer_subscriptions.churn_rate}
              onChange={(e) => updateMultiplayerSubscriptionChurnRate(e.target.value)}
              className="mt-2 p-2 bg-[#1D2026] rounded text-white"
            />
          </>
        )}
      </section>
    </section>
  );
}
