import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Homepage from './pages/Homepage';
import Projections from './pages/Projections';
import UserAcquisition from './pages/UserAcquisition';
import DevelopmentMilestones from './pages/DevelopmentMilestones';
import ExitStrategy from './pages/ExitStrategy';
import AppLayout from './layout/AppLayout';
import ScrollToTop from './layout/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/projections" element={<Projections />} />
          <Route path="/user_acquisition" element={<UserAcquisition />} />
          <Route path="/development_milestones" element={<DevelopmentMilestones />} />
          <Route path="/exit_strategy" element={<ExitStrategy />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;