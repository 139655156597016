import { createContext, useContext, useState, useEffect } from "react";
import configurationData from "./SimulationData.json"

const SimulationContext = createContext();

export const useSimulation = () => {
  return useContext(SimulationContext);
};

export const scenarioConstants = {
  best_case: {
    marketingConversionRate: 1.0, // No change
    wordOfMouthGrowthRate: 0.02, // 2%
    multiplayerChurnRate: 1.0, // No change
    yearlyReleaseRetentionRate: 1.0, // No change
  },
  moderate_case: {
    marketingConversionRate: 0.95, // Slightly lower conversion
    wordOfMouthGrowthRate: 0.015, // 1.5%
    multiplayerChurnRate: 1.1, // 10% increase in churn
    yearlyReleaseRetentionRate: 0.95, // 5% lower retention
  },
  worst_case: {
    marketingConversionRate: 0.9, // More reduced conversion
    wordOfMouthGrowthRate: 0.01, // 1%
    multiplayerChurnRate: 1.2, // 20% increase in churn
    yearlyReleaseRetentionRate: 0.9, // 10% lower retention
  }
};

export function SimulationProvider({ children }) {
  const [result, setResult] = useState(null);

  const [configuration, setConfiguration] = useState(configurationData);


  const simulateFinancials = (config) => {
    const { quarters, investmentDetails, current_quarter } = config;
    const scenario = config.scenario;
    const scenarioConfig = scenarioConstants[scenario];
    let cumulativeCashFlow = investmentDetails.totalRaised;
    let revenueOverTime = [];

    let freeUsers = 0;
    let multiplayerUsers = 0;
    let totalMobileUsers = 0;
    let totalFullGameUsers = 0;
    let mobileCooldown = {};
    let fullGameCooldown = {};
    let returningFullGameUsers = {};

    for (let quarter = 1; quarter <= current_quarter; quarter++) {
      const quarterConfig = quarters[quarter];
      const { income, expenses } = quarterConfig;

      let newFreeUsers = 0;
      for (var channel of expenses.marketing.channels) {
        const conversions = (channel.percentageBudgetAllocated / 100) * channel.totalReach * channel.frequency * channel.clickThroughRate * channel.conversionToFreeGameRate * scenarioConfig.marketingConversionRate;
        newFreeUsers += conversions
      }

      // New free users added each quarter from marketing efforts
      freeUsers += newFreeUsers;
      const wordOfMouthNewUsers = Math.round(freeUsers * scenarioConfig.wordOfMouthGrowthRate);
      freeUsers += newFreeUsers + wordOfMouthNewUsers;

      // Reset mobile and full game sales for the quarter
      let mobileGameSales = 0;
      let multiplayerRevenue = 0;
      let fullGameSales = 0;

      // Calculate mobile upsells from free users not on cooldown
      if (quarterConfig.isMobileReady) {
        mobileCooldown[quarter] = mobileCooldown[quarter] || 0;
        const availableForMobile = freeUsers - mobileCooldown[quarter];
        mobileGameSales = Math.min(
          availableForMobile,
          Math.round(freeUsers * (quarterConfig.expenses.marketing.mobileConversionRate || 0.1))
        );
        totalMobileUsers += mobileGameSales;
        for (let i = 1; i <= 3; i++) {
          mobileCooldown[quarter + i] = (mobileCooldown[quarter + i] || 0) + mobileGameSales;
        }
      }

      if (quarterConfig.isMultiplayerReady) {
        // Apply churn on the current multiplayer user base
        const multiplayerChurn = Math.round(
          multiplayerUsers * ((quarterConfig.income.multiplayer_subscriptions.churn_rate / 100) * scenarioConfig.multiplayerChurnRate)
        );
        multiplayerUsers = Math.max(0, multiplayerUsers - multiplayerChurn); // Remove churned users

        // Define the cap for multiplayer users as a percentage of free users
        const maxMultiplayerUsers = Math.round(freeUsers * 0.005); // 30% of free users, adjustable

        // Calculate potential new multiplayer subscriptions
        const eligibleFreeUsersForMultiplayer = freeUsers;
        let newMultiplayerSubs = Math.round(
          eligibleFreeUsersForMultiplayer * (quarterConfig.expenses.marketing.multiplayerConversionRate || 0.05) * scenarioConfig.marketingConversionRate
        );

        // Adjust new multiplayer subscriptions if it exceeds the cap
        if ((multiplayerUsers + newMultiplayerSubs) > maxMultiplayerUsers) {
          newMultiplayerSubs = maxMultiplayerUsers - multiplayerUsers; // Limit to the cap
        }

        // Add new subscribers after applying churn, with cap in place
        multiplayerUsers += newMultiplayerSubs;

        // Ensure multiplayerUsers does not exceed freeUsers
        if (multiplayerUsers > freeUsers) {
          multiplayerUsers = freeUsers;
        }

        // Calculate multiplayer revenue based on the updated multiplayer user base
        multiplayerRevenue = multiplayerUsers * income.multiplayer_subscriptions.pricing;
      }

      // Calculate full game sales from free users not on cooldown
      if (quarterConfig.isFullGameReady) {
        fullGameCooldown[quarter] = fullGameCooldown[quarter] || 0;
        const availableForFullGame = freeUsers - fullGameCooldown[quarter];

        const newSales = Math.min(
          availableForFullGame,
          Math.round(freeUsers * (quarterConfig.expenses.marketing.fullGameConversionRate || 0.01))
        );

        let returningSales = 0;
        if (returningFullGameUsers[quarter - 3]) {
          returningSales = Math.round(
            returningFullGameUsers[quarter - 3] * (quarterConfig.income.yearly_release.retention_rate / 100) * scenarioConfig.yearlyReleaseRetentionRate
          );
        }

        fullGameSales = newSales + returningSales;
        totalFullGameUsers += fullGameSales;

        for (let i = 1; i <= 3; i++) {
          fullGameCooldown[quarter + i] = (fullGameCooldown[quarter + i] || 0) + fullGameSales;
        }
        returningFullGameUsers[quarter] = fullGameSales;
      }

      // Revenue calculation
      let revenueQuarter = (mobileGameSales * income.mobile_app.pricing) +
        (fullGameSales * income.yearly_release.pricing) +
        multiplayerRevenue;

      // Expense calculation
      const developmentExpense = parseFloat(expenses.development.budget);
      const marketingExpense = parseFloat(expenses.marketing.budget);
      const supportExpense = parseFloat(expenses.support.budget);
      const accountingExpense = parseFloat(expenses.accounting.budget);
      const legalExpense = parseFloat(expenses.legal.budget);
      const softwareLicensesExpense = parseFloat(expenses.software_licenses.budget);
      const serverHostingExpense = parseFloat(expenses.server_hosting.budget);
      const expensesQuarter = developmentExpense + marketingExpense + supportExpense
        + accountingExpense + legalExpense + softwareLicensesExpense + serverHostingExpense;

      // Profit and Cash Flow
      const profitQuarter = revenueQuarter - expensesQuarter;
      cumulativeCashFlow += profitQuarter;

      // Push quarterly data to revenueOverTime
      revenueOverTime.push({
        quarterId: quarter,
        quarter: `Q${(quarter % 4) || 4} ${2025 + Math.floor((quarter - 1) / 4)}`,
        revenue: revenueQuarter,
        expenses: expensesQuarter,
        profit: profitQuarter,
        cashFlow: cumulativeCashFlow,
        freeUsers: freeUsers,
        fullGameSales: fullGameSales,
        mobileGameSales: mobileGameSales,
        multiplayerSubscriptions: multiplayerRevenue,
        users: {
          freeUsers: freeUsers,
          mobileUsers: totalMobileUsers,
          multiplayerUsers: multiplayerUsers,
          fullGameUsers: totalFullGameUsers,
        }
      });
    }

    // Summarize results
    const totalRevenue = revenueOverTime.reduce((sum, q) => sum + q.revenue, 0);
    const totalExpenses = revenueOverTime.reduce((sum, q) => sum + q.expenses, 0);
    const netProfit = revenueOverTime.reduce((sum, q) => sum + q.profit, 0);

    return {
      revenue_over_time: revenueOverTime,
      net_profit: netProfit,
      total_revenue: totalRevenue,
      total_expenses: totalExpenses,
      current_users: {
        free: freeUsers,
        mobile: totalMobileUsers,
        multiplayer: multiplayerUsers,
        fullGame: totalFullGameUsers
      },
      final_reserve: cumulativeCashFlow
    };
  };

  useEffect(() => {
    const data = simulateFinancials(configuration);
    setResult(data);
  }, [configuration]);

  return (
    <SimulationContext.Provider value={{ configuration, setConfiguration, result, simulateFinancials }}>
      {children}
    </SimulationContext.Provider>
  );
}