import MarketingImage4 from '../../../images/marketing-4.png';

export default function MarketingSlideExistingEfforts() {
  return (
    <div className="flex w-1/5 h-[200px] items-center bg-gradient-to-b from-[#252A35]/40 to-transparent space-x-4">
      <div className="relative flex flex-col justify-center w-full p-5 py-auto h-full text-left bg-contain bg-no-repeat bg-right" style={{
        backgroundImage: `url(${MarketingImage4})`,
      }}>
        <h2 className="text-[15px] leading-tight w-1/2 text-gray-400">
          If the same sales ratio could be replicated in wrestling <span className="font-BarlowBold text-textactive">why haven't existing efforts caught on?</span>
        </h2>
        <p className="flex flex-row items-center justify-start ml-gap-2 text-[14px] font-BarlowBlack mt-3 uppercase">Because they <br />look like this</p>
        <div className="arrow">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  )
}