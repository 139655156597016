import { useSimulation } from "../../contexts/SimulationContext";
import { LineChart, Line, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function CashFlowForecastPanel() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  return (
    <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Cash Flow Forecast</h1>
      </section>
      <section className="flex p-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">
        <div className="bg-gradient-to-b from-transparent to-[#252A35] rounded-xl h-[500px] p-5 flex items-center justify-center text-gray-500">
          <ResponsiveContainer width="100%" height={500}>
            <LineChart data={result?.revenue_over_time || []}>
              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <XAxis dataKey="quarter" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="cashFlow" stroke="#82ca9d" name="Cash Flow" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </section>
    </section>
  );
}