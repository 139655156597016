import clsx from 'clsx';
import { useState, useRef } from 'react';

import MarketingSlideTotalMarketSize from './MarketOpportunityPanel/MarketingSlideTotalMarketSize';
import MarketingSlideSalesRatio from './MarketOpportunityPanel/MarketingSlideSalesRatio';
import MarketingSlideExistingEfforts from './MarketOpportunityPanel/MarketingSlideExistingEfforts';
import MarketingSlideOpportunity from './MarketOpportunityPanel/MarketingSlideOpportunity';

import WWE2KLogo from '../../images/competition-1.png';
import TEWLogo from '../../images/competition-2.png';
import PWSLogo from '../../images/competition-3.png';
import JOWLogo from '../../images/competition-4.png';
import REPLogo from '../../images/competition-5.png';
import PlusIcon from '../../layout/Icons/PlusIcon';
import MinusIcon from '../../layout/Icons/MinusIcon';


const competitors = [
  {
    logo: WWE2KLogo,
    url: "https://wwe.2k.com/",
    active: false,
    name: 'WWE 2K',
    whatIsIt: 'A mainstream wrestling action title produced by WWE. Recognizing fan demand for fantasy booking, WWE includes a GM mode, allowing players to control one of their brands',
    publisher: 'WWE / THQ',
    price: '$59.99',
    threatToUs: "Unlikely to move into our 'strategy' niche. Only concern with WWE is their rightful obligation to defend their IP could make our marketing efforts tougher since we can't mention their wrestlers or trademarks",
    platforms: ['PlayStation', 'Xbox', 'PC', 'Steam'],
  },
  {
    logo: REPLogo,
    url: "https:/ringexecutivepro.com",
    active: true,
    name: 'Ring Executive Pro',
    whatIsIt: 'This is where we want to position ourselves. As the "Football Manager" to WWE2K\'s action offering. Our game is priced at the highest point we can charge for a text based game',
    publisher: 'N/A',
    price: '$40.00',
    threatToUs: "We already look like a premium quality game and we will invest in balancing so the quality of the game matches it's visual appeal. Our marketing efforts will be more aggresive than anyone has attempted so far",
    platforms: ['Windows', 'Mac', 'Mobile', 'Steam'],
  },
  {
    logo: TEWLogo,
    url: "https://greydogsoftware.com/title/total-extreme-wrestling-ix-2/",
    active: false,
    name: 'Total Extreme Warfare',
    whatIsIt: 'The most well known wrestling management game. Whilst the game is balanced, the UI is dreadful. Further releases are not possible unless the codebase is overhauled',
    publisher: 'Greydog Software',
    price: '$37.40',
    threatToUs: 'Visual Basic support in future Windows releases is being sunset so to compete Greydog would need to invest in rebuilding the game from scratch and do much better in designing and marketing the game',
    platforms: ['Windows', 'Steam'],
  },
  {
    logo: PWSLogo,
    url: "https://store.steampowered.com/app/1157700/Pro_Wrestling_Sim/",
    active: false,
    name: 'Pro Wrestling Sim',
    whatIsIt: 'This is a solo effort that has gained credibility as a modern alternative to TEW. However the biggest complaint is the game is not "fun" to play despite looking modern.',
    publisher: 'Independent Dev',
    price: '$19.99',
    threatToUs: "The source code is not encrypted so we have a unique insight into the quality of his codebase. By Q1 2025, we hope it would be a no-brainer to play a well balanced game like REP for free then to spend money on PWS",
    platforms: ['PC', 'Mac', 'Steam'],
  },
  {
    logo: JOWLogo,
    url: "https://www.journeyofwrestling.com/",
    active: false,
    name: 'Journey of Wrestling',
    whatIsIt: 'While the game does not look professionally designed it gets favorable reviews for it\'s gameplay and works everywhere (including on mobile!)',
    publisher: 'Clean Bean Studios',
    price: '14.99',
    threatToUs: 'Our mobile offering will be far superior and crucially similarly priced to give fans a clear cut choice. Additionally we will make it possible for fan created content to be easily converted to our game',
    platforms: ['iOS', 'Android', 'Browser/Mobile', 'Steam'],
  },
];

export default function MarketOpportunityPanel() {

  const [activePanel, setActivePanel] = useState('market_opportunity');
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjust scroll speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Market Opportunity and Competitor Comparison</h1>
        <div className="gap-2 flex flex-row items-center">
          <button
            id="toggleFilterDropdownButton"
            type="button"
            onClick={() => { setActivePanel('market_opportunity') }}
            className={clsx(
              "bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 rounded-xl shadow-md shadow-black/50 text-[14px] font-BarlowBold uppercase",
              { "opacity-45": activePanel !== 'market_opportunity' },
            )}
          >
            Market Opportunity
          </button>
          <button
            id="toggleFilterDropdownButton"
            type="button"
            onClick={() => { setActivePanel('competitor_comparison') }}
            className={clsx(
              "bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 rounded-xl shadow-md shadow-black/50 text-[14px] font-BarlowBold uppercase",
              { "opacity-45": activePanel !== 'competitor_comparison' },
            )}
          >
            Competitor Comparison
          </button>
        </div>
      </section>
      <section className="flex flex-col h-full bg-[#080C0D] rounded-b-xl overflow-hidden text-white">

        {activePanel === 'market_opportunity' && (
          <div className="space-y-5">
            <div className="flex justify-between items-center divide-x divide-gray-800">
              <MarketingSlideTotalMarketSize />
              <MarketingSlideSalesRatio />
              <MarketingSlideExistingEfforts />
              <MarketingSlideOpportunity />
            </div>
          </div>
        )}

        {activePanel === 'competitor_comparison' && (
          <div
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleMouseUp}
            className="flex overflow-x-auto space-x-4 py-5 px-5 cursor-grab active:cursor-grabbing"
          >
            {competitors.map((competitor, index) => (
              <div
                key={index}
                className={clsx(
                  "relative cursor-default flex items-center text-center flex-col gap-5 w-[550px] p-4 pt-6 rounded-lg shadow-lg flex-shrink-0",
                  { "bg-gradient-to-b from-transparent to-[#252A35] opacity-80 hover:opacity-100": !competitor.active },
                  { "bg-gradient-to-b from-promoterblue1 to-promoterblue4": competitor.active }
                )}
              >
                <a href={competitor.url} target="blank" className="flex justify-center w-full h-[75px] pt-1">
                  <img src={competitor.logo} alt={`${competitor.name} logo`} className="object-fill" />
                </a>
                <div className="flex flex-col items-center">
                  <h3 className="text-[20px] font-BarlowBold text-white mb-2">
                    <a href={competitor.url} className="hover:underline" target="blank">
                      {competitor.name}
                    </a>
                  </h3>
                  <p className="text-[16px] text-gray-400 mb-5">{competitor.whatIsIt}</p>
                  <p className="text-sm text-gray-400 mb-1"><strong className="text-white">Publisher:</strong> {competitor.publisher}</p>
                  <p className="text-sm text-gray-400 mb-4"><strong className="text-white">Price:</strong> {competitor.price}</p>
                  <button onClick={() => {
                    setExpanded(prev => { return !prev })
                  }}>
                    {(!expanded) ? (<PlusIcon
                      className="mb-2 mt-2 cursor-pointer w-[28px] h-[28px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse"
                    />) : (
                      <MinusIcon className="mb-2 mt-2 cursor-pointer w-[28px] h-[28px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
                    )}
                  </button>
                  {expanded && (
                    <p className="text-sm text-textactive">
                      <strong className="text-sm text-white block mb-2">{(!competitor.active) ? 'The threat they pose us' : 'Our advantage'}</strong>
                      {competitor.threatToUs}
                    </p>
                  )}
                  <div className="flex justify-center space-x-2 mt-6 mb-3">
                    {competitor.platforms.map((platform, i) => (
                      <span
                        key={i}
                        className={clsx(
                          "text-xs text-white px-2 py-1 rounded",
                          { "bg-blue-700": competitor.active },
                          { "bg-gray-700": !competitor.active },
                        )}
                      >
                        {platform}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
    </section >
  )
}