import { useSimulation } from "../contexts/SimulationContext";
import SpannerIcon from "../layout/Icons/SpannerIcon";
import ChangeCompletionQuarterDropdown from "./DevelopmentMilestones/ChangeCompletionQuarterDropdown";
import DevelopmentMilestonesHeader from "./DevelopmentMilestones/DevelopmentMilestonesHeader";
import clsx from 'clsx';
import { useState, useEffect, useCallback } from 'react';

const freeGameMilestones = [
  {
    key: "planning",
    name: "Planning and Research",
    description: "Initial game design requirements and planning",
    risks: "Completed",
    riskLevel: 'low'
  },
  {
    key: "design",
    name: "Graphics Design",
    description: "Develop art direction for the game and mock up the key screens",
    risks: "Mostly completed and has received acclaim",
    riskLevel: 'low'
  },
  {
    key: "core_gameplay",
    name: "Core Gameplay Functionality",
    description: "Implement the basic gameplay modules: roster management, feuds and show booking, television and event handling etc",
    risks: "Potential challenges in getting the remaining functionality in place by the new year",
    riskLevel: 'high'
  },
  {
    key: "balancing",
    name: "Game Loop / Mechanics / Balancing",
    description: "Balance the game so it provides a compelling experience that keeps players engaged",
    risks: "Need to find a competent games designer and work with them to tweak base mechanics in the game",
    riskLevel: 'high'
  },
  {
    key: "qa_testing",
    name: "QA Testing and Pre-Launch Preparation",
    description: "Ensure the game works on all target platforms and iron out all bugs",
    risks: "Cross platform issues might take a while to resolve",
    riskLevel: 'medium'
  },
  {
    key: "launch",
    name: "Launch and Post-Launch Support",
    description: "Officially release the game and address any post launch issues",
    risks: "Unanticipated bugs or server issues could affect player retention",
    riskLevel: 'low'
  }
];

const mobileVersionMilestones = [
  {
    key: "planning",
    name: "Planning and Research",
    description: "Identify core gameplay elements to retain or adapt for the mobile game",
    risks: "Minimal issues expected",
    riskLevel: 'low'
  },
  {
    key: "design",
    name: "UI/UX Redesign for Mobile",
    description: "Redesign user interface specifically for smaller screens, focusing on touch interactions and readability",
    risks: "We will need to find a quality designer and design extensive specs for them",
    riskLevel: 'medium'
  },
  {
    key: "frontend",
    name: "Porting to React Native",
    description: "Adapt the existing React codebase to React Native for cross-platform mobile compatibility",
    risks: "No major issues expected apart from usual software development challenges",
    riskLevel: 'low'
  },
  {
    key: "backend",
    name: "Backend Adaptation for Mobile",
    description: "Develop a dedicated mobile backend for handling mobile-specific issues (e.g. latency)",
    risks: "Syncing database state might poses a few issues",
    riskLevel: 'medium'
  },
  {
    key: "qa_testing",
    name: "Q/A Testing",
    description: "Conduct extensive testing across multiple device types, OS versions, and screen sizes",
    risks: "We will need to invest in our testing platform",
    riskLevel: 'low'
  },
  {
    key: "deployment",
    name: "App Store Deployment",
    description: "Prepare app store requirements and submit the app for review",
    risks: "Multiple submission cycles might be needed to satisfy their requirements",
    riskLevel: 'high'
  }
];

const multiplayerMilestones = [
  {
    key: "planning",
    name: "Planning and Research",
    description: "Define the infrastructure requirements and game design changes needed to support multiplayer mode",
    risks: "Minimal issues expected, mostly around scope definition",
    riskLevel: 'low'
  },
  {
    key: "infrastructure",
    name: "Infrastructure Setup",
    description: "Set up remote server infrastructure for storing and syncing player data in real-time",
    risks: "Potential challenges with server scalability",
    riskLevel: 'high'
  },
  {
    key: "game_client",
    name: "Game Client Modification",
    description: "Update the game client to manage multiplayer interactions, including matchmaking, session management, and player data sync",
    risks: "Requires handling of client-server interactions to avoid data loss or sync errors",
    riskLevel: 'medium'
  },
  {
    key: "backend",
    name: "Backend Development for Multiplayer",
    description: "Implement server-side logic to handle game state updates, player actions, and session management",
    risks: "Might require optimizing the database queries the game makes",
    riskLevel: 'high'
  },
  {
    key: "deployment",
    name: "Deployment and Monitoring",
    description: "Launch the multiplayer mode and establish monitoring for server health and user experience",
    risks: "Unexpected post-launch issues could impact player retention if not addressed quickly",
    riskLevel: 'high'
  }
];

const firstYearlyUpdateMilestones = [
  {
    key: "planning",
    name: "Planning and Content Design",
    description: "Identify new content and features based on player feedback",
    risks: "Potential scope creep if too many features are requested or planned",
    riskLevel: 'medium'
  },
  {
    key: "features",
    name: "Feature Development",
    description: "Implement planned new features and any requested improvements",
    risks: "Technical challenges may arise when adding new mechanics affecting core game code",
    riskLevel: 'high'
  },
  {
    key: "bug_fixes",
    name: "Bug Fixes and Quality Assurance",
    description: "Address known bugs and conduct QA testing to ensure stability",
    risks: "Fixing complex bugs may take longer than expected impacting release schedule",
    riskLevel: 'medium'
  },
  {
    key: "launch",
    name: "Localization and Launch",
    description: "Launch the yearly update and handle any post-launch issues",
    risks: "Unexpected post-launch bugs or performance issues",
    riskLevel: 'high'
  }
];

export default function DevelopmentMilestones() {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const [currentQuarterConfig, setCurrentQuarterConfig] = useState(null);

  useEffect(() => {
    setCurrentQuarterConfig(configuration.quarters[configuration.current_quarter])
  }, [configuration, configuration.current_quarter])

  const setCompletionQuarter = useCallback(({ projectKey, quarter }) => {
    setConfiguration(prevConfig => {
      const updatedQuarters = {};

      for (let q in prevConfig.quarters) {
        const quarterNum = parseInt(q);
        updatedQuarters[quarterNum] = {
          ...prevConfig.quarters[quarterNum],
          [projectKey]: quarterNum >= quarter.quarterId
        };
      }

      return {
        ...prevConfig,
        quarters: updatedQuarters
      };
    });
  }, [setConfiguration]);

  return (
    <div className="space-y-5 text-white">

      <DevelopmentMilestonesHeader />

      {/* Free Game */}
      <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
        <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
          <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Free Game</h1>
        </section>
        <section className="flex gap-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">
          <div className="flex bg-[#111111] px-5 py-3 flex-row gap-3 justify-between items-center">
            <div className="flex flex-row gap-3 items-center">
              <span className="text-gray-400">Available in this Quarter</span>
              <span className="font-BarlowBold bg-green-500 px-1.5 py-0.5 rounded-lg">
                Available
              </span>
            </div>
            <ChangeCompletionQuarterDropdown projectKey="" setCompletionQuarter={setCompletionQuarter} />
          </div>
          {configuration.current_quarter === 1 && (
            <>
              <p className="mb-0 px-5">For illustrative purposes, the progress bars below show the current state of development of the Free Base Game. I am hoping to have it completed in Q1 2025. On the next tick, the bars will default to 100%.<br />
                The model is assuming the base game is available at the start of the simulation.</p>
            </>
          )}
          <div className="px-5 pb-5 flex flex-col gap-5">
            {freeGameMilestones.map(milestone => {
              return (
                <div className="flex gap-5 items-center justify-between py-4 bg-[#283045]/50 p-6 rounded-lg shadow-lg border border-gray-800">
                  <SpannerIcon className="w-[38px] h-[38px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
                  <div className="flex-1">
                    <h3 className="text-lg font-bold">{milestone.name}</h3>
                    <p className="text-sm text-gray-400">{milestone.description}</p>
                  </div>
                  <div className="flex-1 text-right">
                    <p>Completion: <span className="font-bold">{currentQuarterConfig?.developmentProgress?.freeGame[milestone.key]}%</span></p>
                    <div className="bg-gray-700 h-3 rounded-full overflow-hidden my-1">
                      <div className="h-full bg-promoterblue1 transition-all duration-3500 ease-in-out" style={{ width: currentQuarterConfig?.developmentProgress?.freeGame[milestone.key] + '%' }}></div>
                    </div>
                    <p className={clsx(
                      { "text-green-400": milestone.riskLevel === 'low' },
                      { "text-yellow-400": milestone.riskLevel === 'medium' },
                      { "text-red-400": milestone.riskLevel === 'high' },
                    )}>
                      {milestone.risks}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </section>

      {/* Mobile Version */}
      <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
        <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
          <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Mobile Version</h1>
        </section>
        <section className="flex gap-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">
          <div className="flex bg-[#111111] px-5 py-3 flex-row gap-3 justify-between items-center">
            <div className="flex flex-row gap-3 items-center">
              <span className="text-gray-400">Available in this Quarter</span>
              <span className={clsx(
                "font-BarlowBold px-1.5 py-0.5 rounded-lg",
                { "bg-red-500": !currentQuarterConfig?.isMobileReady },
                { "bg-green-500": currentQuarterConfig?.isMobileReady },
              )}>
                {currentQuarterConfig?.isMobileReady ? 'Available' : 'Not Available'}
              </span>
            </div>
            <ChangeCompletionQuarterDropdown projectKey="isMobileReady" setCompletionQuarter={setCompletionQuarter} />
          </div>

          <div className="px-5 pb-5 flex flex-col gap-5">
            {mobileVersionMilestones.map(milestone => {
              return (
                <div className="flex gap-5 items-center justify-between py-4 bg-[#283045]/50 p-6 rounded-lg shadow-lg border border-gray-800">
                  <SpannerIcon className="w-[38px] h-[38px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
                  <div className="flex-1">
                    <h3 className="text-lg font-bold">{milestone.name}</h3>
                    <p className="text-sm text-gray-400">{milestone.description}</p>
                  </div>
                  <div className="flex-1 text-right">
                    <p>Completion: <span className="font-bold">{currentQuarterConfig?.developmentProgress?.mobileVersion[milestone.key]}%</span></p>
                    <div className="bg-gray-700 h-3 rounded-full overflow-hidden my-1">
                      <div className="h-full bg-promoterblue1 transition-all duration-3500 ease-in-out" style={{ width: currentQuarterConfig?.developmentProgress?.mobileVersion[milestone.key] + '%' }}></div>
                    </div>
                    <p className={clsx(
                      { "text-green-400": milestone.riskLevel === 'low' },
                      { "text-yellow-400": milestone.riskLevel === 'medium' },
                      { "text-red-400": milestone.riskLevel === 'high' },
                    )}>
                      {milestone.risks}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </section>

      {/* Multiplayer Mode */}
      <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
        <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
          <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Multiplayer Mode</h1>
        </section>
        <section className="flex gap-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">

          <div className="flex bg-[#111111] px-5 py-3 flex-row gap-3 justify-between items-center">
            <div className="flex flex-row gap-3 items-center">
              <span className="text-gray-400">Available in this Quarter</span>
              <span className={clsx(
                "font-BarlowBold px-1.5 py-0.5 rounded-lg",
                { "bg-red-500": !currentQuarterConfig?.isMultiplayerReady },
                { "bg-green-500": currentQuarterConfig?.isMultiplayerReady },
              )}>
                {currentQuarterConfig?.isMultiplayerReady ? 'Available' : 'Not Available'}
              </span>
            </div>
            <ChangeCompletionQuarterDropdown projectKey="isMultiplayerReady" setCompletionQuarter={setCompletionQuarter} />
          </div>

          <div className="px-5 pb-5 flex flex-col gap-5">
            {multiplayerMilestones.map(milestone => {
              return (
                <div className="flex gap-5 items-center justify-between py-4 bg-[#283045]/50 p-6 rounded-lg shadow-lg border border-gray-800">
                  <SpannerIcon className="w-[38px] h-[38px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
                  <div className="flex-1">
                    <h3 className="text-lg font-bold">{milestone.name}</h3>
                    <p className="text-sm text-gray-400">{milestone.description}</p>
                  </div>
                  <div className="flex-1 text-right">
                    <p>Completion: <span className="font-bold">{currentQuarterConfig?.developmentProgress?.multiplayerMode[milestone.key]}%</span></p>
                    <div className="bg-gray-700 h-3 rounded-full overflow-hidden my-1">
                      <div className="h-full bg-promoterblue1 transition-all duration-3500 ease-in-out" style={{ width: currentQuarterConfig?.developmentProgress?.multiplayerMode[milestone.key] + '%' }}></div>
                    </div>
                    <p className={clsx(
                      { "text-green-400": milestone.riskLevel === 'low' },
                      { "text-yellow-400": milestone.riskLevel === 'medium' },
                      { "text-red-400": milestone.riskLevel === 'high' },
                    )}>
                      {milestone.risks}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </section>

      {/* First Yearly Release */}
      <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">
        <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
          <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">First Yearly Release</h1>
        </section>
        <section className="flex gap-5 flex-col h-full bg-[#080C0D] rounded-b-xl text-white">

          <div className="flex bg-[#111111] px-5 py-3 flex-row gap-3 justify-between items-center">
            <div className="flex flex-row gap-3 items-center">
              <span className="text-gray-400">Available in this Quarter</span>
              <span className={clsx(
                "font-BarlowBold px-1.5 py-0.5 rounded-lg",
                { "bg-red-500": !currentQuarterConfig?.isFullGameReady },
                { "bg-green-500": currentQuarterConfig?.isFullGameReady },
              )}>
                {currentQuarterConfig?.isFullGameReady ? 'Available' : 'Not Available'}
              </span>
            </div>
            <ChangeCompletionQuarterDropdown projectKey="isFullGameReady" setCompletionQuarter={setCompletionQuarter} />
          </div>

          <div className="px-5 pb-5 flex flex-col gap-5">
            {firstYearlyUpdateMilestones.map(milestone => {
              return (
                <div className="flex gap-5 items-center justify-between py-4 bg-[#283045]/50 p-6 rounded-lg shadow-lg border border-gray-800">
                  <SpannerIcon className="w-[38px] h-[38px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
                  <div className="flex-1">
                    <h3 className="text-lg font-bold">{milestone.name}</h3>
                    <p className="text-sm text-gray-400">{milestone.description}</p>
                  </div>
                  <div className="flex-1 text-right">
                    <p>Completion: <span className="font-bold">{currentQuarterConfig?.developmentProgress?.yearlyUpdate[milestone.key]}%</span></p>
                    <div className="bg-gray-700 h-3 rounded-full overflow-hidden my-1">
                      <div className="h-full bg-promoterblue1 transition-all duration-3500 ease-in-out" style={{ width: currentQuarterConfig?.developmentProgress?.yearlyUpdate[milestone.key] + '%' }}></div>
                    </div>
                    <p className={clsx(
                      { "text-green-400": milestone.riskLevel === 'low' },
                      { "text-yellow-400": milestone.riskLevel === 'medium' },
                      { "text-red-400": milestone.riskLevel === 'high' },
                    )}>
                      {milestone.risks}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </section>



    </div >

  );
}