import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSimulation } from "../../contexts/SimulationContext";
import clsx from 'clsx';

import TimelineImage1 from '../../images/timeline1.png';
import TimelineImage2 from '../../images/timeline2.png';
import TimelineImage3 from '../../images/timeline3.png';
import TimelineImage4 from '../../images/timeline4.png';
import TimelineImage5 from '../../images/timeline5.png';
import TimelineImage6 from '../../images/timeline6.png';

import JumpToDropdown from "./TimelinePanel/JumpToDropdown";

import RewindIcon from "../../layout/Icons/RewindIcon";
import ForwardIcon from "../../layout/Icons/ForwardIcon";
import FastForwardIcon from "../../layout/Icons/FastForwardIcon";
import BackIcon from "../../layout/Icons/BackIcon";

export const items = [
  {
    image: TimelineImage1,
    title: "Q1 2025: Base Game Launch",
    description: "Launch free-to-play version and begin building a user base.",
    content: [
      "Launching the game for free will attract a huge user base quickly and opens the door for potential monetization down the line",
      "A no-cost game is hard to ignore which will amplify our heavy initial marketing efforts in this quarter through 'word-of-mouth' and organic sharing",
      "Offering the game for free builds community support and goodwill and lets us launch the game with realistic expectations",
    ],
    quarterId: 1
  },
  {
    image: TimelineImage2,
    title: "Q2 2025: Mobile Expansion",
    description: "Release the mobile version to monetize the free game.",
    content: [
      "With a new mobile interface, we can monetize the existing codebase, even while offering the game for free",
      "A mobile version is highly sought after feature; so releasing this not only satisfies existing demand but allows us to promote to new fans not previously reached",
      "Monetizing the mobile app allows us to start generating income without waiting for a full release cycle",
    ],
    quarterId: 2
  },
  {
    image: TimelineImage3,
    title: "Q3 2025: Multiplayer Mode Release",
    description: "Introduce multiplayer mode with paid monthly subscription.",
    content: [
      "We'll let players host multiplayer games, with public servers for competition and private games with friends, available through a monthly subscription",
      "By building outside platforms like Steam, we can explore features like NFTs and microtransactions for in-game purchases",
      "A large free player base gives us room to experiment with new ideas and squeeze a little more out of the existing code base",
    ],
    quarterId: 3
  },
  {
    image: TimelineImage4,
    title: "Q4 2025: International Localization",
    description: "Expand to Japanese and Mexican markets by localizing the game in Japanese and Mexican.",
    content: [
      "Countries like Mexico and Japan have millions of potential players with a deep passion (Lucha Libre / Strong Style) for wrestling",
      "The game's text-based format makes it easier, faster and cheaper to localize for non-English audiences",
      "So at this stage, investing in localization before working on the next release opens the door to a vast new audience, expanding our reach and potential subscriber pool",
    ],
    quarterId: 4
  },
  {
    image: TimelineImage5,
    title: "Q1 2026: First Paid Yearly Update",
    description: "Release first paid yearly update: new content and fan-requested features.",
    content: [
      "We will launch the first yearly update with fan-requested features and a fresh visual overhaul to set it apart from the free version in Q1 2026",
      "Aggressive marketing efforts will be planned to coincide with the release to drive initial sales and build long-term momentum",
      "The aim is to have a strong launch and continued sales throughout the year as the new version gains traction",
    ],
    quarterId: 5
  },
  {
    image: TimelineImage6,
    title: "Q2 2027: Potential Exit Opportunity",
    description: "Explore opportunities to license or sell the IP to AEW, WWE, or a third-party publisher.",
    content: [
      "Our ultimate goal is to sell to one of the major North American wrestling promotions: WWE or AEW",
      "With new TV revenue and interest in expansion, AEW may be open to investing, despite past challenges with game development",
      "WWE's industry monopoly means a large user base would attract their interest—and other lucrative buyers are on the horizon if we establish a strong title",
    ],
    quarterId: 10
  },
];

export default function TimelinePanel() {

  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const navigate = useNavigate();

  const containerRef = useRef(null);
  const [lineWidth, setLineWidth] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [selectedTimeLineEntry, setSelectedTimeLineEntry] = useState(items[0]);
  const [selectedTimeLineEntryIndex, setSelectedTimeLineEntryIndex] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjust scroll speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  useLayoutEffect(() => {
    if (containerRef.current) {
      const totalWidth = containerRef.current.scrollWidth;
      setLineWidth(totalWidth);
    }
  }, [containerRef, items.length]);

  const updateQuarter = (newQuarter) => {
    setConfiguration((prevConfig) => ({
      ...prevConfig,
      current_quarter: Math.max(1, Math.min(20, newQuarter))
    }));
    navigate("/projections");
  };

  return (
    <section className="relative w-full bg-[#252A35]/50 border border-[#2F3341] flex flex-col justify-start rounded-xl shadow-2xl shadow-black/50">

      <section className="flex flex-row w-full justify-between items-center min-h-[50px] pl-4 pr-2">
        <h1 className="uppercase text-[15px] font-BarlowBold text-[#A5A5A5]">Key Milestones and Timeline</h1>
      </section>

      <section
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp} // Stop dragging if mouse leaves the container
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleMouseUp}
        className="flex flex-col w-full h-full overflow-hidden bg-[#080C0D] rounded-b-xl text-white">
        <section className="flex flex-row justify-between bg-[#111111] px-2 py-3 w-full">
          <section className="flex flex-row gap-2 bg-[#222222] p-1.5 rounded-full">
            <button
              onClick={() => {
                setSelectedTimeLineEntry(items[0]);
                setSelectedTimeLineEntryIndex(0);
              }}
              className={`${(selectedTimeLineEntry.title !== items[0].title) ? "bg-promoterblue1" : "bg-[#45474B]"} w-[26px] h-[26px] flex justify-center items-center rounded-full`}>
              <RewindIcon className={`"w-[15px] h-[15px] ${(selectedTimeLineEntry.title !== items[0].title) ? "fill-white" : "fill-[#919191]"}`} />
            </button>
            <button
              onClick={() => {
                const newIndex = Math.max(selectedTimeLineEntryIndex - 1, 0);
                setSelectedTimeLineEntry(items[newIndex]);
                setSelectedTimeLineEntryIndex(newIndex);
              }}
              className={`${(selectedTimeLineEntry.title !== items[0].title) ? "bg-promoterblue1" : "bg-[#45474B]"} w-[26px] h-[26px] flex justify-center items-center rounded-full`}>
              <BackIcon className={`"w-[15px] h-[15px] ${(selectedTimeLineEntry.title !== items[0].title) ? "fill-white" : "fill-[#919191]"}`} />
            </button>
            <button
              onClick={() => {
                const newIndex = Math.min(selectedTimeLineEntryIndex + 1, items.length - 1);
                setSelectedTimeLineEntry(items[newIndex]);
                setSelectedTimeLineEntryIndex(newIndex);
              }}
              className={`${(selectedTimeLineEntry.title !== items[items.length - 1].title) ? "bg-promoterblue1" : "bg-[#45474B]"} w-[26px] h-[26px] flex justify-center items-center rounded-full`}>
              <ForwardIcon className={`w-[15px] h-[15px] ${(selectedTimeLineEntry.title !== items[items.length - 1].title) ? " fill-white" : "fill-[#919191]"}`} />
            </button>
            <button
              onClick={() => {
                setSelectedTimeLineEntry(items[items.length - 1]);
                setSelectedTimeLineEntryIndex(items.length - 1);
              }}
              className={`${(selectedTimeLineEntry.title !== items[items.length - 1].title) ? "bg-promoterblue1" : "bg-[#45474B]"} w-[26px] h-[26px] flex justify-center items-center rounded-full`}>
              <FastForwardIcon className={`w-[15px] h-[15px] ${(selectedTimeLineEntry.title !== items[items.length - 1].title) ? " fill-white" : "fill-[#919191]"}`} />
            </button>
          </section>
          <JumpToDropdown
            setSelectedTimeLineEntry={setSelectedTimeLineEntry}
            setSelectedTimeLineEntryIndex={setSelectedTimeLineEntryIndex}
          />
        </section>
        <div className="cursor-grab active:cursor-grabbing w-full flex flex-col overflow-x-auto py-8 pt-[100px] [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]" ref={containerRef}>

          <div id="timeline-line" className="relative h-1 bg-promoterblue1" style={{ width: lineWidth }}></div>

          <div id="timeline-entry-container" className="flex items-center space-x-12 px-8 -mt-[70px]">
            {items.map((event, index) => (
              <button
                key={event.title}
                onClick={() => {
                  setSelectedTimeLineEntry(event);
                  setSelectedTimeLineEntryIndex(index);
                }}
                className="flex flex-col items-center min-w-[300px] max-w-[300px] h-[90px] text-center cursor-pointer"
              >
                <h3 className={clsx(
                  "text-gray-300 px-3 py-1.5 mb-[24px] font-semibold",
                  { "bg-[#1E222D] rounded-lg": selectedTimeLineEntry.title === event.title },
                )}>
                  {event.title}
                </h3>
                <div className={clsx(
                  { "z-10 w-4 h-4 rounded-full bg-promoterblue1 mb-2": selectedTimeLineEntry.title !== event.title },
                  { "z-10 w-4 h-4 rounded-full bg-[#080C0D] mb-2 border-4 border-promoterblue1": selectedTimeLineEntry.title === event.title },
                )}></div>
              </button>
            ))}
          </div>
        </div>
        <div id="timeline-active-entry" className="relative w-full flex flex-row items-center min-h-[240px] max-h-[240px] mb-5 gap-8 px-20">
          <div className="flex flex-col items-center min-w-[200px] max-w-[200px]">
            <img src={selectedTimeLineEntry.image} alt={selectedTimeLineEntry.title} className="w-full h-full" />
          </div>
          <div className="flex flex-row flex-grow items-center gap-8">
            <div className="w-1/2">
              <h3 className="text-[20px] text-textactive font-BarlowBold">{selectedTimeLineEntry.title}</h3>
              <p className="text-gray-400 mb-3">{selectedTimeLineEntry.description}</p>
              <button
                onClick={() => { updateQuarter(selectedTimeLineEntry.quarterId) }}
                className="bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white my-2 py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
              >
                Jump to Quarter
              </button>
            </div>
            <ul className="w-1/2 list-disc p-3 px-8 bg-[#111111] rounded-xl">
              {selectedTimeLineEntry.content.map((entry, index) => {
                return (<li key={index} className="mb-3">{entry}</li>)
              })}
            </ul>
          </div>
        </div>
      </section>
    </section>
  );
}
