import MarketingImage2 from '../../../images/marketing-2.png';
import MarketingImage3 from '../../../images/marketing-3.png';

export default function MarketingSlideSalesRatio() {
  return (
    <div className="flex flex-row flex-1 w-2/5 px-[30px] h-[200px] justify-center items-center bg-gradient-to-l from-[#252A35]/40 to-transparent space-x-4">
      <div className="w-[200px] h-full bg-contain bg-no-repeat bg-left animate__animated animate__fadeInLeft animate__delay-1s"
        style={{
          backgroundImage: `url(${MarketingImage2})`,
        }}
      ></div>
      <div className="text-center">
        <h2 className="text-gray-300 leading-tight font-BarlowBold text-[22px] animate__animated animate__fadeIn animate__delay-1s">
          Not all sports fans want fast-paced, action-heavy experiences
        </h2>
        <p className="mt-2 text-gray-400 text-[16px] animate__animated animate__fadeInUp animate__delay-2s">
          Instead, many enjoy a thoughtful, strategic approach
          <small className="block text-gray-200 mt-1">(1 in 10 Fifa players plays Football Manager)</small>
        </p>
      </div>
      <div className="w-[200px] h-full bg-contain bg-no-repeat bg-left animate__animated animate__fadeInLeft animate__delay-1s"
        style={{
          backgroundImage: `url(${MarketingImage3})`,
        }}
      ></div>
    </div>
  )
}