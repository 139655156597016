import { useState, useEffect, useRef } from "react";
import { useSimulation } from "../../contexts/SimulationContext";

import SlideShowIcon from '../Icons/SlideshowIcon'
import SettingsIcon from '../Icons/SettingsIcon'
import RewindIcon from '../Icons/RewindIcon'
import BackIcon from '../Icons/BackIcon'
import PlayIcon from '../Icons/PlayIcon'
import PauseIcon from '../Icons/PauseIcon'
import ForwardIcon from '../Icons/ForwardIcon'
import FastForwardIcon from '../Icons/FastForwardIcon'
import PrevIcon from "../Icons/PrevIcon";
import NextIcon from "../Icons/NextIcon";
import VideoIcon from "../Icons/VideoIcon";

export default function Footer() {

  const { configuration, setConfiguration, result, runSimulation } = useSimulation();

  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef(null);
  const sliderRef = useRef(null);

  const updateQuarter = (newQuarter) => {
    setConfiguration((prevConfig) => ({
      ...prevConfig,
      current_quarter: Math.max(1, Math.min(20, newQuarter))
    }));
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      clearInterval(intervalRef.current);
      setIsPlaying(false);
    } else {
      intervalRef.current = setInterval(() => {
        setConfiguration((prevConfig) => {
          const newQuarter = prevConfig.current_quarter + 1;
          if (newQuarter <= 20) {
            return { ...prevConfig, current_quarter: newQuarter };
          } else {
            clearInterval(intervalRef.current);
            setIsPlaying(false);
            return { ...prevConfig, current_quarter: 20 };
          }
        });

      }, 1000);
      setIsPlaying(true);
    }
  };

  const slideLeft = () => {
    // const slider = document.getElementById('quarterSlider');
    // const itemWidth = slider.querySelector('button').offsetWidth;
    // slider.scrollLeft -= itemWidth;
    const itemWidth = sliderRef.current.querySelector('button').offsetWidth;
    sliderRef.current.scrollLeft -= itemWidth;
  };

  const slideRight = () => {
    const itemWidth = sliderRef.current.querySelector('button').offsetWidth;
    sliderRef.current.scrollLeft += itemWidth;
    // const slider = document.getElementById('quarterSlider');
    // const itemWidth = slider.querySelector('button').offsetWidth;
    // slider.scrollLeft += itemWidth;
  };

  useEffect(() => {
    const centerActiveQuarter = () => {
      const slider = sliderRef.current;
      const activeButton = slider.querySelectorAll('button')[configuration.current_quarter - 1];
      const sliderWidth = slider.offsetWidth;
      const itemWidth = activeButton.offsetWidth;
      const scrollPosition = activeButton.offsetLeft - (sliderWidth / 2 - itemWidth / 2);
      slider.scrollLeft = scrollPosition;
    };

    centerActiveQuarter();
  }, [configuration.current_quarter]);

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <div className="flex flex-row justify-between p-5 h-[75px] items-end gap-4 border-t border-gray-800 bg-gradient-to-b from-[#111111] to-[#1d222f]">

      <div className="flex h-full flex-row flex-1 divide-x divide-gray-700 items-center">
        <div className="flex flex-row mr-8">
          <button onClick={() => { slideLeft() }}>
            <PrevIcon className="w-[20px] h-[20px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
          </button>
          <div id="quarterSlider" ref={sliderRef} className="relative w-[400px] h-[70px] flex flex-row gap-3 px-3 items-center overflow-hidden overflow-x-scroll no-scrollbar whitespace-nowrap scroll-smooth scrollbar-hide">
            {[...Array(20)].map((_, index) => {
              const quarter = (index % 4) + 1;
              const year = 2025 + Math.floor(index / 4);
              return (
                <button
                  key={index}
                  type="button"
                  onClick={() => { updateQuarter(index + 1) }}
                  className={(index + 1 === configuration.current_quarter)
                    ? "min-w-[85px] w-[85px] bg-gradient-to-b from-promoterblue1 to-promoterblue3 hover:from-promoterblue1 hover:to-promoterblue4 text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
                    : "min-w-[85px] w-[85px] bg-gradient-to-b from-[#283045] to-[#1d222f] hover:from-[#2d364e] hover:to-[#202634] text-white py-2 px-4 rounded-xl shadow-md shadow-black/30 text-[15px] font-BarlowBold uppercase"
                  }
                >
                  Q{quarter} {year}
                </button>
              );
            })}
          </div>
          <button onClick={() => { slideRight() }}>
            <NextIcon className="w-[20px] h-[20px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
          </button>
        </div>

        <div className="flex flex-row h-full justify-between items-center gap-5 pl-8">
          <button onClick={() => { updateQuarter(1) }}>
            <RewindIcon className="w-[20px] h-[20px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
          </button>
          <button onClick={() => { updateQuarter(configuration.current_quarter - 1); }}>
            <BackIcon className="w-[20px] h-[20px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
          </button>
          <button onClick={togglePlayPause}>
            {!isPlaying ? (
              <PlayIcon className="animate-pulse w-[20px] h-[20px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
            ) : (
              <PauseIcon className="w-[20px] h-[20px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
            )}
          </button>
          <button onClick={() => { updateQuarter(configuration.current_quarter + 1); }}>
            <FastForwardIcon className="w-[20px] h-[20px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
          </button>
          <button onClick={() => { updateQuarter(20) }}>
            <ForwardIcon className="w-[20px] h-[20px] fill-gray-500 hover:fill-gray-200 hover:animate-pulse" />
          </button>
        </div>
      </div>

      <div className="flex flex-row items-center gap-5">
        <a target="blank" href="/ring_executive_pro_pitch_deck_q4_2024.pdf">
          <SlideShowIcon className="w-[28px] h-[28px] fill-gray-500 hover:fill-gray-200" />
        </a>
        <a target="blank" href="https://www.youtube.com/watch?v=E5_-6KhGLR8">
          <VideoIcon className="w-[28px] h-[28px] fill-gray-500 hover:fill-gray-200" />
        </a>
      </div>

    </div>
  )
}