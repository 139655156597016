import MarketingImage5 from '../../../images/marketing-5.png';

export default function MarketingSlideOpportunity() {
  return (
    <div className="flex flex-col gap-2 w-1/5 h-[200px] bg-gradient-to-r from-[#252A35]/40 to-transparent">
      <div className="relative flex flex-col p-5 text-center justify-center w-full p-5 py-auto h-full text-left bg-cover bg-no-repeat bg-left" style={{
        backgroundImage: `url(${MarketingImage5})`,
      }}>
        <h2 className="text-[15px] leading-tight text-gray-400">
          Capturing 2.54M players at a $40 price point
        </h2>
        <p className="pl-0 leading-tight text-[20px] text-white font-BarlowBold">
          Potential revenue could reach <span className="text-textactive block">$101.6 million</span>
        </p>
      </div>
    </div>
  )
}