import { Link } from "react-router-dom";
import Logo from '../../images/logo_small.png';
import NavigationLink from "./TopBar/NavigationLink";

export default function TopBar({ openSideBar }) {

  return (
    <div className="text-[16px] flex justify-between items-center px-5 w-full h-[70px] min-h-[70px] bg-topbarbg-finish z-60 shadow-2xl shadow-black/50">
      <div>
        <Link className="flex flex-row items-center gap-5" to="/">
          <img src={Logo} alt="Logo" width="58" height="58" />
        </Link>
      </div>

      <div className="flex flex-row gap-20 text-gray-400">
        <NavigationLink title="Overview" link="/" />
        <NavigationLink title="Projections" link="/projections" />
        <NavigationLink title="User Acquisition" link="/user_acquisition" />
        <NavigationLink title="Development Milestones" link="/development_milestones" />
        <NavigationLink title="Exit Strategy" link="/exit_strategy" />
      </div>

      <div className="flex flex-row text-white">
        <svg onClick={() => openSideBar(prevValue => { return !prevValue })} className="cursor-pointer w-[32px] h-[32px] fill-gray-400 hover:fill-gray-200" id="fi_6878146" enableBackground="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
          <path d="m7.8 2c-1.5 0-2.7.4-3.7 1-.4.3-.8.7-1.1 1.1-.6 1-1 2.2-1 3.7v8.4c0 3.6 2.2 5.8 5.8 5.8h7.5v-20zm4.3 10.5-2.6 2.6c-.2.1-.3.2-.5.2s-.4-.1-.5-.2c-.3-.3-.3-.8 0-1.1l2-2-2-2c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l2.6 2.6c.2.3.2.7-.1 1z"></path>
          <path d="m16.8 2v20c1.2-.1 2.3-.4 3.1-1 .4-.3.8-.7 1.1-1.1.7-1 1-2.2 1-3.7v-8.4c0-3.4-1.9-5.6-5.2-5.8z"></path>
        </svg>
      </div>
    </div>
  )

}