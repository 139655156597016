const formatCurrency = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
}).format(value);

export default function SoftwareLicensingRow({ value, max, updateFunc }) {
  return (
    <div className="p-5 bg-[#252A35]/40 rounded-lg">
      <div className="flex items-center justify-between">
        <span className="pr-5 w-[150px] text-white">Software Licensing</span>
        <input
          id="range"
          type="range"
          step="1000"
          min="0"
          max={max}
          value={value}
          onChange={(e) => updateFunc("software_licenses", e.target.value)}
          className="block w-full px-3 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md"
        />
        <span className="pl-5 w-[100px] text-right text-gray-300">{formatCurrency(value)}</span>
      </div>
    </div>
  );
}