export default function SlideshowIcon({ className = "" }) {

  return (
    <svg version="1.1" className={className} id="fi_726194" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
      <g>
        <g>
          <path d="M496,32H16C7.168,32,0,39.136,0,48v32c0,8.832,7.168,16,16,16h16v256c0,17.664,14.368,32,32,32h176v39.424l-40.864,27.264
            c-7.36,4.896-9.344,14.848-4.448,22.176c4.864,7.328,14.752,9.312,22.176,4.448L256,451.232l39.136,26.08
            c2.72,1.824,5.792,2.688,8.864,2.688c5.152,0,10.208-2.496,13.312-7.136c4.896-7.328,2.912-17.28-4.448-22.176L272,423.424V384
            h176c17.664,0,32-14.336,32-32V96h16c8.832,0,16-7.168,16-16V48C512,39.136,504.832,32,496,32z M128,320H96v-96h32V320z M192,320
            h-32V160h32V320z M336,320c-44.128,0-80-35.904-80-80c0-38.624,27.52-70.944,64-78.368V256h94.368
            C406.944,292.48,374.656,320,336,320z M352,224v-62.368C383.296,168,408,192.704,414.368,224H352z"></path>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )

}
