import { useEffect, useState } from "react";
import { useSimulation } from "../../contexts/SimulationContext";
import { FunnelChart } from 'react-funnel-pipeline'
import 'react-funnel-pipeline/dist/index.css'
import { scenarioConstants } from '../../contexts/SimulationContext';

export default function FunnelPanel({ localConfiguration }) {
  const { configuration, setConfiguration, result, runSimulation } = useSimulation();
  const [funnelData, setFunnelData] = useState([
    { name: 'Total Impressions', value: 0 },
    { name: 'Visits', value: 0 },
    { name: 'Acquisition (Free App)', value: 0 },
    { name: 'Mobile Upsell', value: 0 },
    { name: 'Multiplayer Upsell', value: 0 },
    { name: 'Full Game Purchase', value: 0 },
  ]);

  useEffect(() => {
    let totalImpressions = 0;
    let totalVisits = 0;
    let totalConversions = 0;
    let mobileUpsells = 0;
    let multiplayerUpsells = 0;
    let fullGamePurchases = 0;

    if (localConfiguration !== null) {
      const scenario = configuration.scenario;
      const scenarioConfig = scenarioConstants[scenario];
      localConfiguration.expenses.marketing.channels.forEach(channel => {
        const impressions = (channel.percentageBudgetAllocated / 100) * channel.totalReach * channel.frequency;
        const visits = (channel.percentageBudgetAllocated / 100) * channel.totalReach * channel.frequency * channel.clickThroughRate;
        const conversions = (channel.percentageBudgetAllocated / 100) * channel.totalReach * channel.frequency * channel.clickThroughRate * channel.conversionToFreeGameRate * scenarioConfig.marketingConversionRate;

        totalImpressions += impressions;
        totalVisits += visits;
        totalConversions += conversions;
      });
    }

    mobileUpsells = Math.min(Math.ceil(totalConversions * localConfiguration?.expenses.marketing.mobileConversionRate), totalConversions);
    multiplayerUpsells = Math.min(Math.ceil(totalConversions * localConfiguration?.expenses.marketing.multiplayerConversionRate), totalConversions);
    fullGamePurchases = Math.min(Math.ceil(totalConversions * localConfiguration?.expenses.marketing.fullGameConversionRate), totalConversions);

    setFunnelData([
      { name: 'Total Impressions', value: totalImpressions },
      { name: 'Visits', value: totalVisits },
      { name: 'Acquisition (Free App)', value: totalConversions },
      { name: 'Mobile Upsell', value: localConfiguration?.isMobileReady ? mobileUpsells : 0 },
      { name: 'Multiplayer Upsell', value: localConfiguration?.isMultiplayerReady ? multiplayerUpsells : 0 },
      { name: 'Full Game Purchase', value: localConfiguration?.isFullGameReady ? fullGamePurchases : 0 },
    ])

  }, [localConfiguration])

  return (
    <div className="w-1/2 flex flex-col justify-center items-center">
      <div className="w-full">
        <FunnelChart
          data={funnelData}
        />
      </div>
    </div>
  )
};