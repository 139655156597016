import { NavLink } from "react-router-dom";

export default function NavigationLink({ title, link }) {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? 'text-textactive font-BarlowBold hover:text-gray-200'
          : 'text-[#8e9095] hover:text-gray-200'
      }
      to={link}
    >
      {title}
    </NavLink>
  )
}