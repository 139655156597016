import React, { useMemo } from 'react';
import { useSimulation } from '../../contexts/SimulationContext';

export default function BurnRateRunwayPanel() {
  const { result, configuration } = useSimulation();

  const monthlyBurnRate = useMemo(() => {
    if (result) {
      const currentQuarterResults = result.revenue_over_time.find(entry => entry.quarterId === configuration.current_quarter);
      const quarterlyExpense = currentQuarterResults?.expenses || 0;
      return Math.round(quarterlyExpense / 3);
    }
  }, [result, configuration]);

  const runway = useMemo(() => {
    if (result) {
      const cashOnHand = result.final_reserve || 0;
      return Math.round(cashOnHand / monthlyBurnRate);
    }
  }, [result, monthlyBurnRate]);

  return (
    <div className="w-1/4 p-5 bg-[#252A35]/50 rounded-lg border border-[#2F3341] text-white shadow-lg">
      <h2 className="text-lg font-bold mb-4 uppercase">Burn Rate & Runway</h2>

      <div className="flex flex-col divide-y divide-slate-800">
        <div className="flex py-2 justify-between items-center">
          <span className="text-gray-400">Current Cash on Hand:</span>
          <span className="text-white font-bold">
            ${result?.final_reserve ? result.final_reserve.toLocaleString() : '0'}
          </span>
        </div>

        <div className="flex py-2 justify-between items-center">
          <span className="text-gray-400">Monthly Burn Rate:</span>
          <span className="text-red-500 font-bold">
            ${monthlyBurnRate ? monthlyBurnRate.toLocaleString() : '0'}
          </span>
        </div>

        <div className="flex py-2 justify-between items-center">
          <span className="text-gray-400">Runway (Months):</span>
          <span className="text-white font-bold">
            {runway ? `${runway} months` : '∞'}
          </span>
        </div>

        <p className="text-gray-300 pt-3">
          At the current burn rate of <span className="text-red-500">${monthlyBurnRate ? monthlyBurnRate.toLocaleString() : '0'}</span> per month,
          our runway is estimated to be <span className="text-textactive">{runway} months</span>.
        </p>
      </div>
    </div>
  );
};