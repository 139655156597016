export default function ForwardIcon({ className = "" }) {

  return (
    <svg height="512" className={className} viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
      <g id="_04-Next" data-name="04-Next">
        <rect height="28" rx="3" width="8" x="22" y="2" />
        <path d="m7.3 2.84a3.16 3.16 0 0 0 -5.3 2.32v21.68a3.16 3.16 0 0 0 5.3 2.32l11.64-10.75a3.27 3.27 0 0 0 0-4.82z" />
      </g>
    </svg>
  )

}