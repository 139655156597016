export default function PauseIcon({ className = "" }) {

  return (
    <svg height="512" className={className} viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg">
      <g id="_03-Pause" data-name="03-Pause">
        <rect height="28" rx="3" width="10" x="4" y="2" />
        <rect height="28" rx="3" width="10" x="18" y="2" />
      </g>
    </svg>
  )

}